/*paginator*/

.paginator-wrapper {
  .mat-form-field {
    width: 100px;
    // .mat-select-placeholder {
    //     color: $black;
    // }
    &.mat-focused {
      .mat-input-element {
        color: $primary;
      }
      .mat-form-field-flex {
        border: 1px solid $primary;
      }
    }
  }

  .mat-paginator-input {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mat-form-field {
      width: auto;
    }

    p {
      font-size: 16px;
      white-space: nowrap;
      margin: 0 12px 0 4px;
    }

    .mat-form-field-flex {
      width: 50px;
      background-color: $white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.12);

      .mat-form-field-infix {
        padding: 0 4px;
      }
    }
  }
  &.sidenav-paginator {
    position: absolute;
    bottom: 0;
    width: 100%;
    .paginator-container {
      position: inherit;
      border: 1px solid $gray-300;
      border-radius: 0 0 8px 8px;
      width: auto;
      padding: 10px 20px;
    }
    .pagination-toggle {
      position: absolute;
      margin-left: 0;
      display: none;
    }
  }
}
