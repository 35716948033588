.card {
  box-shadow: 0px 0px 10px 0px rgba($black, 0.08);
  border-radius: 14px;
  background-color: $white;
  padding: 20px;
  flex: 1;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(34, 43, 56, 0.25);
    filter: blur(4px);
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }

  &-body {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-bottom: 5px;
  }

  &-img {
    background-color: $primary;
    border-radius: 100px;
    width: 36px;
    height: 36px;
    line-height: 46px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 5px 7px 0 rgba(102, 97, 184, 0.4);

    .mat-icon {
      color: $white;
      font-size: 20px;
      line-height: 30px;
      width: 25px;
      height: 25px;
    }
  }

  &-text {
    span {
      font-size: 20px;
      line-height: 24px;
      color: $primary;
      font-weight: 600;
    }

    p {
      margin-bottom: 5px;
      font-size: 14px;
      color: $primary-200;
      font-weight: 600;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mat-typography {
  .card {
    &-text {
      h2 {
        font-size: 14px;
        font-weight: 600;
        color: $gray-300;
        line-height: 14px;
        margin: 0;
      }
    }
  }
}
