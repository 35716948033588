.mat-tab-group {
  .mat-tab-header {
    background-color: $white;
    position: inherit;
    z-index: 1;
    top: 56px;
    border: 0;
    border-radius: 10px;
    padding: 0 10px;
    // box-shadow: 0px 0px 4px 1px rgba($black, 0.1);
    overflow: visible;

    .mat-tab-label {
      opacity: 1;
      height: 48px;
      min-width: auto;
      padding: 0 1px;
      margin-right: 15px;

      &.mat-tab-label-active {
        .mat-tab-label-content {
          color: $primary;
        }
      }
    }

    .mat-tab-label-container,
    .mat-ripple {
      overflow: visible;
    }

    .mat-tab-label-content {
      color: $gray-100;
      font-size: 16px;
    }

    .mat-ink-bar {
      border-radius: 10px;
      height: 4px;
      background-color: $primary;
    }
  }

  .mat-tab-body {
    &.mat-tab-body-active {
      overflow: hidden;
    }

    .mat-tab-body-content {
      overflow: hidden;
      padding: 2px 2px;
    }
  }

  &-sidenav {
    .mat-tab-header {
      background-color: transparent;
      position: inherit;
      border-bottom: 1px solid $gray-300;

      .mat-tab-label {
        margin: 0 30px 0 0;
        padding: 0;

        &.mat-tab-label-active {
          background-color: transparent;

          .mat-tab-label-content {
            color: $primary;
          }
        }

        &:hover:not(.mat-tab-label-active) {
          background-color: inherit;

          .mat-tab-label-content {
            color: $gray-300;
          }
        }
      }

      .mat-tab-label-content {
        color: $gray-300;
      }

      .mat-ink-bar {
        display: block;
        background-color: $gray-100;
      }
    }

    // .mat-tab-body-wrapper {
    //   padding: 15px 0;
    // }
  }
}

mat-tab-group[vertical] {
  display: block;
  transition: all 0.2s;

  .mat-tab-header {
    background-color: $primary-200;
    position: fixed;
    width: 80px;
    overflow-x: hidden;
    bottom: 0;
    padding: 10px;
    border-radius: 0 15px 15px 0;
    box-shadow: 2px 0px 4px rgba(113, 130, 214, 0.15);

    .mat-tab-label {
      justify-content: center;
      height: auto;
      padding: 7px;
      margin-bottom: 5px;

      .mat-tab-label-content {
        display: flex;
        flex-direction: column;
        width: auto;
        .mat-icon {
          padding-right: 0;
          color: $primary-100;
          padding-left: 0 !important;
        }
        span {
          font-size: 12px;
        }
        .unsubmitted-tab {
          flex-direction: column;
        }
        .mat-tab-title-wrapper {
          button {
            position: absolute;
            background-color: $primary-200;
            color: $white;
            padding: 7px 10px;
            line-height: normal;
            height: auto;
            left: 90px;
            top: 50%;
            transform: translateY(-50%);
            display: none;
            border-radius: 10px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

            .mat-icon {
              margin-left: 12px;
              color: $primary;
            }

            span:not(.mat-button-wrapper) {
              font-size: 14px;
              font-weight: 600;
              color: $primary;
            }

            &:after {
              content: "";
              display: block;
              position: absolute;
              left: -10px;
              top: 50%;
              width: 0;
              height: 0;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
              border-right: 12px solid $primary-200;
              transform: translateY(-50%);
              filter: drop-shadow(-13px 1px 9px rgba(0, 0, 0, 0.4));
            }
          }
        }
      }

      &.mat-tab-label-active {
        background-color: $primary;
        border-radius: 10px;

        .mat-tab-label-content {
          color: $white;

          .mat-icon {
            color: $white;
          }
        }
      }

      &:hover:not(.mat-tab-label-active) {
        background-color: $primary-300;
        border-radius: 10px;
      }
      &:hover {
        .mat-tab-label-content {
          .mat-tab-title-wrapper {
            button {
              display: block;
            }
          }
        }
      }
    }

    .mat-tab-label-content {
      font-weight: 600;
      color: $primary-100;
    }

    .mat-ink-bar,
    .mat-tab-header-pagination {
      display: none;
    }
  }

  .mat-tab-labels {
    display: flex;
    flex-direction: column;
  }

  .mat-tab-body-wrapper {
    margin-left: auto;
    width: calc(100% - 100px);
    transition: all 0.2s;
  }

  .mat-tab-group {
    .mat-tab-label {
      &.mat-tab-label-active {
        background-color: $gray-700;

        .mat-tab-label-content {
          color: $gray-100;
        }
      }

      &:hover:not(.mat-tab-label-active) {
        background-color: $primary;

        .mat-tab-label-content {
          color: $white;
        }
      }
    }

    .mat-tab-header {
      position: fixed;
      top: 40px;
      width: 100%;
      bottom: auto;
      padding-top: 0;
    }

    .mat-tab-labels {
      flex-direction: inherit;
    }

    .mat-tab-body-wrapper {
      padding: 0 15px 15px;
    }
  }

  mat-tab-group[horizontal] {
    .mat-tab-header {
      background-color: $white;
      box-shadow: 0px 0px 5px rgba($black, 0.12);
      position: sticky;
      top: 0;
      border-radius: 10px;
      padding: 0 0 0 10px;
      width: auto;

      .mat-tab-label {
        height: 48px;
        padding: 0 24px;
        margin: 0;

        .mat-tab-label-content {
          color: $gray-300;
          font-weight: 400;
        }

        &.mat-tab-label-active {
          background-color: transparent;

          .mat-tab-label-content {
            color: $primary;
          }
        }

        &:hover:not(.mat-tab-label-active) {
          background-color: transparent;
          color: $gray-300;

          .mat-tab-label-content {
            color: $gray-300;
          }
        }
      }

      .mat-ink-bar {
        display: block;
        border-radius: 10px;
        height: 4px;
        background-color: $primary;
        min-width: 100px;
      }
    }

    .mat-tab-body-wrapper {
      width: 100%;
      padding: 0;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-700;
  }
}

.send-tab-content,
.invoice-types {
  .mat-tab-group-sidenav {
    .mat-tab-header {
      border: 0;

      .mat-ink-bar {
        background-color: $primary;
      }
    }
  }
}
