.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: end !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.gap-5 {
  gap: 5px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-40 {
  gap: 40px !important;
}
