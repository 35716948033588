.main-contain {
  .e-calendar {
    .e-content {
      td.e-today {
        span.e-day {
          border: 1px solid $primary;
          color: $primary;
        }
      }
      td.e-selected {
        span.e-day {
          background-color: $primary;
        }
        &.e-today {
          &:hover {
            span.e-day {
              background-color: $primary;
              border: none;
              box-shadow: none;
            }
          }
        }
        &.e-focused-date {
          span.e-day {
            background-color: $primary;
            border: none;
            box-shadow: none;
            color: $white;
          }
        }
        &:hover {
          span.e-day {
            background-color: $primary;
            border: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .e-btn {
    &.e-flat {
      &.e-primary {
        color: $primary;
        &:hover,
        &:focus {
          color: $primary;
          background-color: #eee;
        }
      }
    }
  }
  .e-schedule {
    .e-vertical-view {
      .e-header-cells.e-current-day {
        color: $primary;
      }
      .e-day-wrapper {
        .e-appointment {
          background: rgb(220, 246, 223);
          color: $gray-100;
          padding: 2px 4px;
        }
      }
    }
    .e-month-view {
      .e-date-header-wrap {
        table {
          td.e-current-day {
            color: $primary;
          }
        }
      }
      .e-appointment {
        background: rgb(220, 246, 223);
        color: $gray-100;
        display: block;
        cursor: pointer;
        padding-left: 3px;
        height: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .e-current-date {
        .e-date-header {
          background: $primary;
        }
      }
    }
    .e-schedule-toolbar {
      &.e-toolbar {
        .e-toolbar-item {
          .e-tbar-btn {
            .e-tbar-btn-text {
              padding: 0 6px 0 6px;
            }
          }
        }
      }
      .e-active-view {
        .e-tbar-btn-text {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
}

.user-details {
  .e-schedule {
    .e-month-view {
      .e-date-header {
        margin: 3px auto 2px;
      }
      .e-appointment {
        border: none;
        .e-appointment-details {
          text-align: center;
          .e-time {
            display: none;
          }
        }
      }
    }
  }
}
