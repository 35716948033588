@import "./variables";
@import "./reset";
@import "./mixins";
@import "./colors";
@import "./default";
@import "./material-overrides";
@import "./spacers";
@import "./text-align";

//components
@import "./sidebar";
@import "./table";
@import "./tabs";
@import "./button";
@import "./dialog";
@import "./expansion";
@import "./card";
@import "./file";
@import "./menu";
@import "./alerts";
@import "./pagination";
@import "./notification";
@import "./stepper";
@import "./scheduler";
@import "./responsive";

//themes
@import "~@angular/material/theming";
@include mat-core();
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$dynamic-theme-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    A100: var(--theme-primary-contrast-A100),
    A200: var(--theme-primary-contrast-A200),
    A400: var(--theme-primary-contrast-A400),
    A700: var(--theme-primary-contrast-A700),
  ),
);

$app-theme: mat-light-theme(
  mat-palette($dynamic-theme-primary),
  mat-palette($mat-purple)
);

@include angular-material-theme($app-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
