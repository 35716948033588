.action-wrapper {
  position: fixed;
  bottom: 0;
  background-color: $white;
  width: calc(100% - 350px);
  z-index: 101;
  transition: all 0.2s;

  &.import-list {
    width: calc(100% - 100px);
  }

  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
}

.tab-pages {
  .action-wrapper {
    width: calc(100% - 100px);
    left: 100px;
  }
}

.timesheet-popup {
  &.hide-sidelist {
    .action-wrapper {
      width: calc(100% - 130px);
    }
  }
}

.hide-sidelist {
  .action-wrapper {
    width: calc(100% - 130px);
  }
}

button {
  &.mat-button {
    line-height: 40px;
    padding: 0;
    min-width: auto;

    &:hover {
      .mat-button-focus-overlay {
        &:not(.mat-button-disabled) {
          opacity: 0;
        }
      }
    }
  }

  &.primary-button {
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;
    font-weight: 600;
    min-width: 100px;
    line-height: 38px;
    border-radius: 50px;
    padding: 0 20px;
    box-shadow: $box-shadow-500;
    transition: all 0.2s;

    &:hover {
      background-color: $primary-100;
      border-color: $primary-100;
      color: $white;
    }
    &.live-button {
      background-color: $white;
      color: #ff1919;
      border-radius: 6px;
      min-width: 76px;
      line-height: 32px;
      padding: 0 8px;
      .mat-icon {
        color: #ff1919;
      }
    }
  }

  &.secondary-button {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    font-weight: 600;
    min-width: 100px;
    line-height: 38px;
    border-radius: 50px;
    padding: 0 20px;
    box-shadow: $box-shadow-500;
    transition: all 0.2s;
    .mat-icon {
      color: $primary;
    }

    &:hover {
      background-color: $primary-200;
      border-color: $primary;
      color: $primary;
    }
  }

  &.border-button {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    font-weight: 600;
    min-width: 100px;
    line-height: 38px;
    border-radius: 50px;
    padding: 0 20px;
    box-shadow: $box-shadow-500;
  }

  &.shadow-button {
    border: 0;
    box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
    font-weight: 400;
    padding: 0 20px;
    min-width: 100px;
    line-height: 38px;
    color: $gray-100;
  }

  &.gray-border-button {
    background-color: $gray-800;
    color: $gray-100;
    border: 1px solid $gray-100;
    font-weight: 600;
    width: 75px;
    height: 75px;
    line-height: 38px;
    border-radius: 50%;
    padding: 0 20px;

    .mat-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 30px;
    }
  }

  &.button-icon {
    background: #f8f8f8;
    box-shadow: 0 -10px 20px $white, 10px 10px 20px rgba(174, 174, 192, 0.2);
    border-radius: 16px;
    border: 0;
    width: 80px;
    height: 80px;
    line-height: normal;
    margin-right: 15px;
    transition: all 0.2s;
    &:hover {
      background-color: $primary-300;
      transform: scale(1.1);
      .mat-icon {
        color: $primary;
      }
      p {
        color: $primary;
      }
    }

    p {
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      color: $gray-400;
      margin: 8px 0 0;
      white-space: break-spaces;
    }

    .mat-icon {
      color: $gray-400;
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
    }
  }

  &.download-button {
    line-height: normal;

    .mat-icon {
      margin-right: 5px;
    }
  }

  &.back-button {
    line-height: normal;

    .mat-icon {
      margin-right: 5px;
    }

    span {
      span {
        text-decoration: underline;
      }
    }
  }
}

// toggle button
.mat-button-toggle-group {
  .mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
      line-height: 40px;
      padding: 0 10px;
    }
    .mat-button-toggle-focus-overlay {
      background-color: $white;
    }
  }
  .mat-button-toggle-checked {
    background-color: $primary;
    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        color: $white;
        .mat-icon {
          color: $white;
        }
      }
    }
  }
}
