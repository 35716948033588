// expansion panel css
.mat-accordion {
  .mat-expansion-panel {
    box-shadow: none;
    margin-bottom: 20px;
    background: transparent;
    border-radius: $border-radius !important;

    &:not([class*="mat-elevation-z"]) {
      box-shadow: $box-shadow-100;
    }

    &-header {
      background: $white;
      padding: 0 15px;
      height: 55px;
      border-left: 5px solid $primary;
      .border-button {
        margin: 5px 0;
      }

      &-title {
        color: $primary;
        font-weight: 600;
        font-size: 18px;

        .mat-icon {
          border: 1px solid $primary;
          border-radius: 50%;
          padding: 7px;
          margin-right: 20px;
        }
      }

      &.mat-expanded {
        background-color: $primary-200;
        border: 0;
        border-radius: 5px;
        border-left: 5px solid $primary;
        height: 55px;

        &:hover {
          background: $primary-200;
        }

        &:focus {
          background: $primary-200;
        }
      }
    }

    &-content {
      background-color: $white;
      border-radius: $border-radius;
      position: relative;
    }

    &-body {
      padding: 0;
    }

    .panel-content {
      &-wrapper {
        padding: 25px 0;
        display: flex;
        justify-content: space-between;
        gap: 50px;

        .vertical-box {
          border: 1px solid rgba($black, 0.1);
          box-shadow: 0px 1px 1px 0px rgba($primary-100, 0.25);
          border-radius: 5px;
          padding: 20px;
          height: 100%;
        }
      }

      &-left,
      &-right {
        flex: 1;

        &-box {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }
    }
  }

  .mat-expansion-indicator::after {
    margin-top: -3px;
    color: $primary;
  }

  .mat-expansion-panel-spacing {
    margin: 20px 0;
    overflow: visible;
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: $white;
  }
}
