.ngx-timepicker-control__input,
.mat-expansion-panel-content,
.mat-expansion-panel-header,
.mat-stepper-horizontal,
.mat-stepper-vertical,
.mat-typography h2,
.mat-typography h5,
.mat-radio-button,
.mat-icon-button,
.mat-typography,
.mat-form-field,
.mat-menu-item,
.mat-tab-label,
.mat-tab-link,
.mat-tab-group,
.mat-checkbox,
.mat-button,
.mat-option,
.mat-table,
.mat-select {
  font-family: $fontFamily !important;
}

.mat-primary {
  .mat-option {
    &.mat-selected:not(.mat-option-disabled) {
      color: $white;
      background: $primary-100;
    }
  }
  &.checkPanelClass {
    .mat-option {
      &.mat-selected:not(.mat-option-disabled) {
        color: $gray-100;
        background: $white;
      }
      &.mat-active {
        color: $gray-100 !important;
        background: $white !important;
      }
    }
  }
  .mat-pseudo-checkbox {
    border-color: rgba($black, 0.25);
    border-width: 2px;
    border-radius: 6px;
    color: $gray;
  }
}

.mat-option {
  &.mat-active {
    color: $white !important;
    background: $primary !important;
  }

  &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
    background: $primary;
    color: $white;
  }
}

.mat-button {
  &.cdk-focused {
    &.cdk-program-focused {
      .mat-button-focus-overlay {
        opacity: 0;
      }
    }
  }
}

.mat-icon-button {
  &.cdk-focused {
    &.cdk-program-focused {
      .mat-button-focus-overlay {
        opacity: 0;
      }
    }
  }
  .mat-ripple {
    &.mat-button-ripple {
      background-color: transparent;
      opacity: 0;
    }
  }
}

.mat-button-focus-overlay {
  background-color: transparent;
}

.mat-dialog-actions {
  margin-bottom: 0;
}

.mat-menu-panel {
  &.profile-setting-menu {
    margin-right: 0;
    overflow: auto !important;
    padding: 20px;

    &:before {
      display: none;
    }
  }

  &.profile-settings {
    min-width: 184px !important;
    //overflow: auto !important;
  }

  &.more-menu {
    &:before {
      right: 15px;
    }
  }

  &.cost-menu {
    &:before {
      filter: drop-shadow(0px 0 2px rgba($black, 0.25));
    }
  }

  &.invoice-more-menu {
    margin-right: 0;

    &:before {
      display: none;
    }
  }

  &.contact-detail {
    min-width: 400px !important;
    margin-right: 70px;

    &:before {
      left: 10px;
    }

    .mat-menu-content {
      padding: 20px !important;
    }

    .form-field-title {
      p {
        color: $primary;
        border-bottom: 1px solid $gray-300;
        padding-bottom: 6px;
        margin-bottom: 5px;
      }
    }

    .mat-table-wrapper {
      max-height: 180px;
      overflow: auto;

      .mat-table {
        .mat-row {
          &:last-child {
            .mat-cell {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &.time-tooltip {
    min-width: 490px !important;
    margin-left: -10px;
  }

  &.job-detail {
    min-width: 850px !important;

    &:before {
      left: 10px;
    }

    .mat-menu-content {
      padding: 20px !important;
    }

    .form-field-title {
      p {
        color: $primary;
        border-bottom: 1px solid $gray-300;
        padding-bottom: 6px;
        margin-bottom: 5px;
      }
    }

    .mat-table-wrapper {
      max-height: 180px;
      overflow: auto;

      .mat-table {
        .mat-row {
          &:last-child {
            .mat-cell {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &.teammate-details {
    max-width: 210px !important;

    .mat-menu-content {
      padding: 20px !important;
    }

    &:before {
      left: 20px;
      right: auto;
    }

    .mat-table-wrapper {
      max-height: 300px;
      height: auto;
      overflow: auto;
    }
  }

  &.taxes-details {
    .form-field-title,
    .taxes-wrapper {
      padding: 10px 15px;
    }

    .taxes-wrapper {
      max-height: 200px;
      overflow: auto;
      margin: 2px;
    }

    &.mat-menu-above {
      &:before {
        right: 10px;
        left: auto;
      }
    }
  }

  &.highlight {
    margin-right: -5px;

    .highlight-wrapper {
      padding: 20px;

      ul {
        padding-left: 20px;

        li {
          position: relative;

          &:before {
            content: "";
            background-color: #a5b1be;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            position: absolute;
            left: -15px;
            top: 6px;
          }
        }
      }
    }

    &.mat-menu-after {
      margin-left: -20px;

      &::before {
        left: 17px;
      }
    }
  }

  &.header-more-menu {
    margin-right: -7px;

    &:before {
      right: 14px;
    }
  }
}

.profile-settings {
  .rounded-border {
    border-color: $gray-800;
    color: $primary;
    margin: 0 auto 16px auto;
  }
}

.mat-menu-panel,
.process-steps {
  .theme-colors {
    position: inherit;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 8px;
    background-color: $white;
    mat-radio-group {
      display: grid;
      grid-template-columns: auto auto auto auto;
      justify-content: space-around;
      .mat-radio-checked {
        border: none;
      }
    }

    button {
      width: 15px;
      height: 15px;
      border-radius: 4px;
      cursor: pointer;
      line-height: 15px;

      .mat-icon {
        opacity: 0;
        font-size: 15px;
        width: 15px;
        height: 15px;
        line-height: 15px;
      }
    }

    .mat-radio-button {
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 0 2px;
      margin-bottom: 1px;

      .mat-radio-label-content {
        padding: 0;
      }
    }

    .mat-radio-container {
      position: absolute;
      opacity: 0;
      z-index: 1;
    }

    .mat-radio-checked {
      &.mat-radio-button {
        border-color: $primary;
      }

      button {
        .mat-icon {
          opacity: 1;
          color: $white;
        }
      }
    }

    li {
      padding: 2px 3px;
      border: 1px solid transparent;

      &.activeTheme {
        border: 1px solid $white;
        border-radius: 3px;

        .mat-icon {
          opacity: 1;
          color: $white;
        }
      }
    }
  }
}

.process-steps {
  .theme-colors {
    position: relative;

    .mat-radio-button {
      margin-bottom: 0;
      padding: 3px;

      .mat-radio-container {
        width: 25px;
        height: 25px;
      }
    }

    button {
      width: 25px;
      height: 25px;
      line-height: 25px;
    }
  }

  .theme-selector {
    &.purple {
      background-color: $purple-color;
    }

    &.blue {
      background-color: $blue-color;
    }

    &.dark-blue {
      background-color: $dark-blue-color;
    }

    &.light-purple {
      background-color: $light-purple-color;
    }

    &.maroon {
      background-color: $maroon-color;
    }

    &.green {
      background-color: $green-color;
    }
  }
}

.dropdown-menu {
  &.mention-menu {
    border: 0 !important;
    box-shadow: 0px 0px 4px 0px rgba($black, 0.25) !important;
    background-color: $white !important;

    .mention-active {
      > a {
        background-color: $primary !important;
      }
    }
  }
}

.sidenav-body-content {
  &.email-template {
    .form-field {
      &.textarea {
        .mat-input-element {
          height: 205px;
        }

        .mat-form-field-flex {
          height: 230px;
        }
      }
    }
  }
}

/*Select options*/

.mat-select-panel-wrap {
  .mat-select-panel {
    box-shadow: 0px 2px 5px 0px rgba($primary-100, 0.25);
    max-height: 200px;
    min-width: calc(100% + 20px) !important;
    .mat-option {
      height: 40px;
      padding: 0 10px;
      &:last-child {
        border: none;
      }

      &:hover {
        background: $primary-200;
        color: $primary;
      }

      &.mat-selected:not(.mat-option-multiple) {
        background: $primary;
        color: $white;
      }

      &.mat-option:focus:not(.mat-option-disabled) {
        background: $primary-200;
      }
    }
    &.checkPanelClass {
      // min-width: calc(100% + 0px) !important;
      transform: translate(30px, 35px) !important;
    }
    &.project {
      max-height: 260px;
    }
  }
}

.mat-autocomplete-panel {
  max-height: 240px !important;

  &.mat-autocomplete-visible {
    .mat-option {
      height: 38px;
      font-size: 14px;

      &:hover:not(.mat-option-disabled):not(.mat-selected) {
        background-color: $primary-200;
        color: $primary;
      }

      &.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
        background-color: $primary;
        color: $white;
      }

      &.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
        background: $primary;
        color: $white;
      }
    }
  }

  &.inner-width {
    min-width: 260px;
  }

  &.mat-autocomplete-hidden {
    height: 0;
  }
}

/*Sidenav*/

.sidenav-table {
  position: relative;
  .mat-checkbox-inner-container {
    margin-right: 8px;
  }
}

.tab-content {
  .mat-table-wrapper {
    mat-checkbox {
      padding: 0;
    }
  }
}

/*Form Field*/

.myPanelClass {
  transform: translate(3px, 35px) !important;

  &.select-auto {
    min-width: auto !important;
  }
}

.checkPanelClass {
  transform: translate(3px, 35px) !important;
  &.addjob {
    &.mat-select-panel {
      max-height: 170px;
    }
  }

  &.select-auto {
    min-width: auto !important;
  }
}

.pagination {
  transform: translate(0, -35px) !important;
}

mark {
  background-color: $primary-300;
}

.light-bg {
  .container {
    .backdrop {
      //background-color: $gray;
    }
  }
}

.vertical-box {
  .payment-fields {
    .container {
      width: 387px;
      margin: 0;

      .backdrop {
        width: 387px;
      }
    }

    .container:not(.input-container),
    .backdrop:not(.input-backdrop),
    textarea {
      height: 245px !important;
    }

    textarea {
      padding-top: 10px !important;
    }
  }
}

.ae-font.ae-picker,
.color-label.foreground {
  display: none !important;
}

.action-menu {
  .mat-menu-item {
    &.mat-button-disabled {
      cursor: not-allowed;
    }
  }
}

.main-contain {
  .mat-icon {
    width: 21px;
    height: 21px;
    font-size: 21px;
    line-height: 21px;
  }

  .cst-info {
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
    margin-left: 5px;
    margin-top: -5px;
    color: $gray-300;
  }

  .mat-button {
    &.mat-button-disabled {
      cursor: not-allowed;
      .mat-button-wrapper {
        span {
          color: $white;
        }
      }
    }
  }

  .form-field {
    &-name {
      display: flex;

      .mat-form-field {
        width: 205px;
      }
    }
  }

  .add-setting {
    .mat-tab-body-content {
      overflow: hidden;
    }
  }
  .search-field {
    &.mat-form-field {
      .mat-form-field-infix {
        padding: 0 30px 0 10px;
      }
    }
  }

  .mat-form-field {
    &.mat-form-field-disabled {
      cursor: not-allowed;
      .mat-form-field-flex {
        background: $gray-900;
      }
    }
  }
}

.mat-calendar-header {
  .mat-calendar-controls {
    margin: 0;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: $white;
}

.mat-calendar-body {
  .mat-calendar-body-selected {
    color: $white;
  }

  .mat-calendar-body-in-range {
    .mat-calendar-body-cell-content {
      color: $white;
    }
  }
}

.mat-form-field {
  .mat-datepicker-toggle-default-icon {
    color: $gray-100;
  }
}

.main-contain,
.mat-dialog-container {
  .form-field {
    margin-bottom: 15px;

    &.textarea {
      .mat-form-field-flex {
        height: 105px;
        line-height: 22px;
      }

      .mat-form-field-infix {
        height: auto;
      }

      .mat-input-element {
        height: 95px;
        line-height: 22px;
        padding-top: 0;
      }
    }

    .attachFile {
      position: relative;
      border: 2px dashed;
      border-radius: 10px;
      max-width: 250px;
      min-height: 50px;
      padding: 20px;
      border-color: rgb(204, 204, 204);
      display: grid;
      text-align: center;
      cursor: pointer;

      input {
        opacity: 0;
        overflow: hidden;
        margin-bottom: -14px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        cursor: pointer;
      }

      .mat-icon {
        width: 40px;
        height: 40px;
        font-size: 40px;
        line-height: 40px;
        justify-self: center;
        color: $gray-700;
      }
    }
  }

  .mat-form-field {
    width: 260px;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-flex {
      padding: 0;
      border-radius: 6px;
      height: 40px;
      display: flex;
      align-items: center;
      background: transparent;
      box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
    }

    .mat-form-field-suffix {
      .mat-datepicker-toggle-default-icon {
        width: auto;
      }

      .suffix-text {
        padding-right: 10px;
      }

      .mat-icon {
        padding-right: 5px;
        font-size: 24px;
        line-height: 40px;
        cursor: pointer;
        height: 38px;
      }
    }

    .mat-form-field-prefix {
      .mat-datepicker-toggle-default-icon {
        width: auto;
        padding: 0 0 0 6px;
        color: $gray-100;
      }

      .mat-datepicker-toggle-active {
        color: $gray-100;
      }

      .mat-datepicker-toggle {
        .mat-icon-button {
          height: auto;
          width: auto;
        }
      }
    }

    &.is-invalid {
      .mat-form-field-flex {
        border: 1px solid $red;
        box-shadow: none;
      }
    }

    &.mat-focused {
      .mat-form-field-flex {
        border: 1px solid $primary;
      }
    }

    &.mat-form-field-invalid {
      .mat-input-element {
        caret-color: $primary-100;
      }
    }

    .mat-form-field-infix {
      padding: 0 10px;
      border: 0;
      line-height: 14px;
      //width: 240px;
      height: 40px;
      width: auto;

      img {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(-50%, -50%);
      }

      .mat-date-range-input-container {
        text-align: center;
        gap: 3px;
        margin-left: 2px;
      }

      .mat-date-range-input-start-wrapper,
      .mat-date-range-input-end-wrapper {
        height: 40px;
        max-width: calc(50% - 25px);
      }

      .mat-date-range-input-separator {
        margin: 0;
        line-height: 0;
      }
    }

    .mat-select,
    .mat-input-element {
      font-size: 14px;
      line-height: 42px;
      height: 38px;
      position: relative;
      color: $gray-100;
      caret-color: $gray-100;
      margin: 1px 0 0;
    }

    textarea {
      &.mat-input-element {
        resize: none;
      }
    }

    .mat-input-element::-webkit-inner-spin-button {
      display: none;
    }

    .mat-select-trigger {
      height: 30px;
    }

    .mat-select-value {
      font-size: 14px;
      vertical-align: middle;
      color: $gray-100;

      .mat-select-placeholder {
        color: $gray-100;
      }
    }

    .mat-select-arrow-wrapper {
      transform: none;

      .mat-select-arrow {
        background-image: url(../images/arrow.svg);
        background-repeat: no-repeat;
        border: none;
        width: 14px;
        height: 8px;
        margin: 0;
      }
    }

    .mat-form-field-underline {
      display: none;
    }

    &-invalid {
      .mat-form-field-flex {
        border: 1px solid $red;
        box-shadow: none;
      }
    }
  }

  .ng-select {
    padding-bottom: 0;
    width: 260px;
    cursor: pointer;
    &.month-filter {
      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          max-height: 252px;
        }
      }
    }
    &.import-dropdown {
      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          max-height: 162px;
        }
      }
    }

    &.inner-width {
      .ng-dropdown-panel {
        width: 220px;
      }
    }

    .ng-select-container {
      cursor: default;
      display: flex;
      overflow: hidden;
      position: relative;
      width: 100%;
      background: transparent;
      box-shadow: 0px 0px 5px rgba($black, 0.12);
      height: 40px;
      padding: 0;
      border-radius: 5px;
      min-height: 40px;
      align-items: center;

      &:after {
        display: none;
      }

      .ng-arrow-wrapper {
        padding-right: 10px;
        padding-left: 5px;
      }
    }

    &.ng-select-opened {
      outline: 1px solid $primary;
      border-radius: 6px;
    }

    &-disabled {
      .ng-select-container {
        background-color: $gray;
      }
    }

    .ng-clear-wrapper {
      display: none;
    }

    .ng-value {
      font-size: 14px;
    }

    .ng-value-container {
      align-items: center;
      padding-left: 10px;
      border-top: 0;
    }

    .ng-input {
      top: 9px;
      left: 0;
      padding-left: 10px;
      padding-right: 50px;
      cursor: pointer;

      input {
        font-size: 14px;
        line-height: 24px;
        cursor: pointer !important;
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        background-image: url(../images/arrow.svg);
        background-repeat: no-repeat;
        border: none;
        width: 14px;
        height: 8px;
        margin: 0;
      }
    }

    .ng-dropdown-panel {
      box-shadow: 0px 2px 5px 0px rgba($black, 0.25);
      background-color: $white;
      border-radius: 5px;

      &.ng-select-top {
        bottom: 100%;
      }

      &.ng-select-bottom {
        top: 100%;
      }

      .ng-dropdown-panel-items {
        border-radius: 5px;
        max-height: 210px;

        .ng-option {
          &.ng-option-marked {
            background: $primary-200;
            color: $primary;
          }

          &.ng-option-selected {
            background: $primary;
            color: $white;
          }
        }
      }
    }

    &.ng-invalid {
      &.ng-touched {
        outline: 1px solid $red;
        border-radius: $border-radius;
      }
    }
  }

  .timepicker-input {
    .ngx-timepicker {
      border-bottom: 0;
      box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
      border: 0;
      border-radius: 6px;
      width: 283px;
      height: 40px;
      padding: 0 6px;
      justify-content: left;

      &-control__input {
        background-color: transparent;
        font-size: 14px;
        padding: 0;
      }

      &__toggle {
        display: none;
      }

      &__time-colon {
        margin: 0;
      }

      &-control {
        width: 20px;
        padding: 0;
        font-size: 14px;
        display: block;

        &__arrows,
        &::after {
          display: none;
        }
      }

      &--disabled {
        background: transparent;
      }
    }
  }

  .duration-time {
    .ngx-timepicker {
      &--disabled {
        background: $gray;
      }
    }
  }

  angular-editor {
    .angular-editor {
      min-width: 460px !important;

      .angular-editor-wrapper {
        .angular-editor-textarea {
          position: unset !important;
        }
      }
    }
  }
}

.toggle-column {
  .form-field {
    margin-bottom: 0;
  }

  .mat-radio-label {
    align-items: center;

    .mat-radio-label-content {
      padding: 0;
    }
  }

  .mat-form-field {
    width: 100px;

    .mat-form-field-flex {
      height: 25px;
      border-radius: 3px;
    }

    .mat-form-field-infix {
      height: 25px;
      line-height: 14px;
      width: 100px;
    }

    .mat-input-element {
      height: 25px;
    }
  }
}

.invoice-toggle-column {
  display: grid;
  grid-template-columns: auto auto;
  .mat-radio-label {
    align-items: center;
    padding: 5px 0;
    .mat-radio-label-content {
      padding: 0;
    }
  }
}

.mat-dialog-container {
  .ng-select,
  .mat-form-field {
    width: 100%;
  }

  .timesheet {
    .ng-select,
    .mat-form-field {
      width: 295px;
    }
  }

  .mat-radio-button {
    .mat-radio-label {
      align-items: center;
    }

    .mat-radio-label-content {
      padding: 3px 0 3px 0;
    }
  }

  .mat-datepicker-toggle-default-icon {
    color: $gray-100;
  }

  .mat-datepicker-toggle-active {
    color: $gray-100;
  }

  .form-field {
    .attachFile {
      max-width: inherit;
    }

    .input-container {
      position: relative;

      .backdrop,
      textarea,
      input {
        width: 100%;
      }
    }
  }

  .custom-textarea {
    height: 400px;
  }
}

.upload {
  &-wrapper,
  &-file-icon {
    .mat-icon {
      width: 40px;
      height: 40px;
      font-size: 40px;
      line-height: 40px;
    }
  }
}

mat-paginator {
  width: 100%;

  .mat-paginator-container {
    justify-content: space-between;
    align-items: center;
    padding: 0;
    min-height: auto;

    .mat-paginator-page-size-label {
      display: none;
    }

    .mat-form-field {
      width: 130px;
      margin: 0;
    }
  }

  .mat-paginator-range-label {
    order: 3;
    font-size: 14px;
    color: $primary-100;
  }

  .mat-paginator-navigation-previous {
    order: 2;
  }

  .mat-paginator-navigation-next {
    order: 4;
  }

  .mat-paginator-navigation-first {
    order: 1;
  }

  .mat-paginator-navigation-last {
    order: 5;
  }
}

.notifier__notification--material .notifier__notification-message {
  background-color: transparent;
  color: $white;
}

.notifier__notification {
  margin-top: 7px;
}

.mat-radio-group {
  .mat-radio-button {
    display: block;
  }
}

.radiogroup {
  .mat-radio-button {
    min-width: 250px;

    .mat-radio-label {
      white-space: nowrap;
    }
  }
}

.apexcharts-tooltip {
  border: 0 !important;
  background-color: $primary-200 !important;

  &-title {
    background: $primary !important;
    color: $white !important;
  }
}

.apexcharts-yaxis-label,
.apexcharts-legend-text {
  font-size: 12px;
  font-family: $fontFamily !important;
}

.time-fees-input {
  .mat-icon {
    width: 15px;
  }
  .mat-form-field {
    .mat-form-field-infix {
      padding: 0;
      line-height: 40px;
    }
  }
}
