.mat-menu-panel {
  min-width: 138px !important;
  min-height: auto !important;
  box-shadow: 0px 2px 5px 0px rgba($black, 0.25);
  margin-top: 15px;
  margin-right: -10px;
  border-radius: 15px !important;
  overflow: inherit !important;

  .mat-menu-below {
    top: -10px;
  }
  &.mat-menu-before {
    &::before {
      right: 10px;
      left: auto;
    }
  }

  &.mat-menu-after {
    &::before {
      right: auto;
      left: 10px;
    }
  }

  .mat-menu-content {
    background-color: $white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    border-radius: $menu-border-radius;

    .mat-menu-item {
      padding: 0 12px;
      height: 40px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: $gray-100;
      // width: calc(100% - 30px);

      .mat-icon {
        margin-right: 12px;
        font-size: 18px;
        width: 18px;
        height: 18px;
        color: $gray-100;
      }

      &:hover:not([disabled]):not(&.customize-view),
      &.cdk-program-focused:not([disabled]):not(&.customize-view),
      &.cdk-keyboard-focused:not([disabled]):not(&.customize-view),
      &-highlighted:not([disabled]):not(&.customize-view) {
        background-color: $primary-200;
        color: $primary;

        .mat-icon {
          color: $primary;
        }
      }

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: $primary-200;
        color: $primary;

        .mat-icon {
          color: $primary;
        }
      }

      &.customize-view {
        background-color: $primary;
        color: $white;
        border-radius: 0 0 8px 8px;
        width: 100%;
        z-index: 1;

        .mat-icon {
          color: white;
        }
      }
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 13px;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid $white;
    filter: drop-shadow(0px 0 2px rgba($black, 0.25));
  }

  &.mat-menu-above {
    margin-top: 0;
    margin-bottom: 20px;

    &:before {
      bottom: -16px;
      top: auto;
      transform: rotate(180deg);
      left: 10px;
    }
    &.mat-menu-before {
      &:before {
        left: auto;
      }
    }
  }

  &.more-button-menu {
    &:before {
      right: 17px;
      left: auto;
    }
  }
}
