@import "~@ng-select/ng-select/themes/material.theme.css";

.w-auto {
  width: auto !important;
}

.w-60 {
  width: 60px !important;
}

.w-70 {
  width: 70px !important;
}

.w-80 {
  width: 80px !important;
}

.w-90 {
  width: 90px !important;
}

.w-95 {
  width: 95px !important;
}

.w-110 {
  width: 110px !important;
}

.w-100 {
  width: 100px !important;
}

.w-120 {
  width: 120px !important;
}

.w-130 {
  width: 130px !important;
}

.w-140 {
  width: 140px !important;
}

.w-636 {
  width: 636px !important;
}

.w-434 {
  width: 434px !important;
}

.w-760 {
  width: 760px;
}

.w-387 {
  width: 387px !important;
}

.w-295 {
  width: 295px !important;
}

.w-40 {
  width: 40px !important;
}

.w-50 {
  width: 50px !important;
}

.w-55 {
  width: 55px !important;
}

.w-20 {
  width: 20px !important;
}

.w-15 {
  width: 15px !important;
}

.w-150 {
  width: 150px !important;
}

.w-160 {
  width: 160px !important;
}

.w-170 {
  width: 170px !important;
}

.w-190 {
  width: 190px !important;
}

.w-185 {
  width: 185px !important;
}

.w-200 {
  width: 200px !important;
}

.w-210 {
  width: 210px !important;
}

.w-230 {
  width: 230px !important;
}

.w-250 {
  width: 250px !important;
}

.w-270 {
  width: 270px !important;
}

.w-280 {
  width: 280px !important;
}

.wp-100 {
  width: 100% !important;
}

.wp-25 {
  width: 25% !important;
}

.wp-30 {
  width: 30% !important;
}

.wp-50 {
  width: 50% !important;
}

.wp-70 {
  width: 70% !important;
}

.wp-80 {
  width: 80% !important;
}

.wp-33 {
  width: 33.33% !important;
}

.wp-300 {
  width: 300px !important;
}

.mw-100 {
  min-width: 100px !important;
}

.mw-250 {
  min-width: 250px !important;
}

.h-auto {
  height: auto !important;
}

.h-40 {
  height: 40px !important;
}

.h-100 {
  height: 100% !important;
}

.hr {
  border-top: 1px solid $gray-800;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mr-30 {
  margin-right: 30px;
}

.m-auto {
  margin: 0 auto;
}

.hr-bottom {
  border-bottom: 1px solid $gray-800;
}

.hr-left {
  border-left: 1px solid $gray-800;
}

.hr-right {
  border-right: 1px solid $gray-800;
}

.hr-top {
  border-top: 1px solid $gray-800;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-none {
  border: none !important;
}

.text-transform-uppercase {
  text-transform: uppercase !important;
}

.line-height-normal {
  line-height: normal;
}

.hyperlink {
  color: $primary;
  text-decoration: underline;

  &:hover {
    color: $primary-100;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.text-line-through {
  text-decoration: line-through;
}

.box-shadow-none {
  box-shadow: none !important;
}

.text-gray {
  color: $gray-100 !important;
}

.text-light {
  color: #8d8d8d !important;
}

.text-primary {
  color: $primary !important;
}

.gray {
  color: #bfbfbf;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bdr-left {
  border-right: 1px solid $gray-600;
  padding-right: 20px;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.display-inline {
  display: inline-block;
}

.white-space-nowrap {
  white-space: nowrap;
}

.overflow-inherit {
  overflow: inherit !important;
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.close {
  cursor: pointer;
}

.onelineclamp {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.rounded-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.rounded-border {
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid $primary;
  margin-right: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: $primary;
  text-transform: uppercase;

  img {
    border-radius: 50%;
  }
}

.hidden {
  display: none !important;
}

.white-bg {
  background-color: $white;
}

.light-bg {
  background-color: $gray;
}

.subheader-container {
  .container-body {
    height: calc(100vh - 240px);
  }
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.no-border {
  border: 0 !important;
}

.border-left {
  border-left: 1px solid $gray-300;
}

.gray-border {
  border: 1px solid $gray-800 !important;
}

.link {
  text-decoration: underline;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
}

.text-underline {
  text-decoration: underline !important;
}

.title {
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-300;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

body,
html {
  overflow: hidden;

  &:hover {
    overflow: auto;
  }
}

.module-list {
  img {
    filter: brightness(0) invert(1);
  }
}

.mat-tooltip {
  font-size: 12px !important;
  margin: 5px !important;
  border-radius: $border-radius !important;
  box-shadow: 0px 0px 5px rgba($black, 0.15);
  overflow: inherit !important;
  white-space: pre-line;
  line-height: 25px;
  background: $white !important;
  color: $gray-100 !important;

  // &::after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   right: -10px;
  //   top: 50%;
  //   width: 0;
  //   height: 0;
  //   border-left: 10px solid $primary-200;
  //   border-bottom: 5px solid transparent;
  //   border-top: 5px solid transparent;
  //   transform: translateY(-50%);
  // }
}

.mat-tooltip-panel-right {
  .mat-tooltip {
    &::after {
      left: -10px;
      border-right: 10px solid rgba(90, 27, 116, 0.7);
      border-left: none;
    }
  }
}

.side-list {
  width: 250px;
  background-color: $white;
  color: $gray-100;
  border-radius: 0 15px 15px 0;
  box-shadow: 2px 0px 5px rgba(97, 19, 127, 0.15);
  transition: all 0.2s;
  z-index: 102;

  .menu-toggle {
    &.side-list-toggle {
      left: 447px;
    }
  }

  &.hide-sidelist {
    width: 30px;

    .side-list-wrapper {
      display: none;
    }
  }

  .mat-table {
    border: 0;

    thead {
      display: none;
    }

    .mat-header-row .mat-header-cell {
      background-color: $primary;
      border: 0;
      color: $white;
    }

    .mat-row {
      height: 36px;

      &:hover {
        box-shadow: none;
      }

      .mat-cell {
        color: $gray-100;
        border: 0;
        padding: 0;
        font-weight: 400;
        border-radius: 10px;

        &:hover {
          box-shadow: none;
          color: $gray-100;

          .list-wrapper {
            background-color: $primary-200;
            color: $primary;

            a {
              color: $primary !important;
            }
          }

          mat-checkbox {
            .mat-checkbox-frame {
              border-color: $primary;
            }
          }
        }

        &.active {
          .list-wrapper {
            background-color: $primary-200;
            color: $primary;

            .text-gray-100 {
              color: $primary !important;
            }

            .list-name {
              color: $primary;
            }
          }

          mat-checkbox {
            .mat-checkbox-frame {
              border-color: $primary;
            }
          }
        }

        mat-checkbox {
          .mat-checkbox-ripple {
            left: calc(50% - 15px);
            top: calc(50% - 15px);
            height: 30px;
            width: 30px;
          }
        }
      }

      &:first-child {
        &.highlightRow {
          .mat-cell {
            background-color: transparent;

            .list-wrapper {
              background-color: $primary-200;
            }
          }
        }
      }

      &.highlightCopiedRow {
        .mat-cell {
          background-color: transparent;

          .list-wrapper {
            background-color: $primary-200;
          }
        }
      }
    }
  }

  mat-checkbox {
    &.mat-checkbox-checked {
      .mat-checkbox-checkmark {
        fill: $gray-500;
        width: 12px;
        left: 2px;
        top: 2px;
      }
    }

    .mat-checkbox-inner-container {
      margin-right: 10px;
    }

    .mat-checkbox-frame {
      border-color: $gray-400;
    }

    &:hover {
      .mat-checkbox-frame {
        border-color: $primary;
      }
    }
  }

  .list-wrapper {
    padding: 7px 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .list-name {
    display: block;
    line-height: normal;
    font-weight: 400;
    flex: 1;
    color: $gray-100;
  }
}

.time {
  .side-list-wrapper {
    .side-list-header {
      padding: 0 0 0 10px;
    }
  }
}

.mat-button {
  &.close-btn {
    min-width: 20px;
    padding: 0;
  }
}

.status {
  &-active {
    color: $success;
  }

  &-archived {
    color: $danger;
  }
}

.draft-status {
  img {
    max-width: 100%;
    height: 50px;
  }
}

.subheader {
  &-menu {
    background-color: $white;
    border-radius: 15px 15px 0 0;

    a {
      line-height: 22px;
      padding: 20px 15px 20px 20px;
      text-decoration: none;
      display: inline-block;
      font-size: 14px;

      &.active {
        color: $primary-100;
        position: relative;

        &::after {
          background-color: $primary-100;
        }
      }

      &::after {
        content: "";
        height: 2px;
        width: 100%;
        display: block;
        background-color: transparent;
        border-radius: 10px;
        margin-top: 1px;
      }
    }
  }
}

.menu-toggle {
  position: fixed;
  left: 335px;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  transition: all 0.2s;
  background-color: $white;
  color: $primary;
  box-shadow: $box-shadow-100;
  cursor: pointer;

  &.toggle-icon {
    left: 115px;
  }

  .mat-icon {
    line-height: 32px !important;
  }

  &:hover {
    background-color: $primary;

    .mat-icon {
      color: $white;
    }
  }

  &-stick-bottom {
    bottom: -9px;
  }
}

.container {
  width: calc(100% - 100px);
  margin-left: auto;
  height: calc(100vh - 56px);
  overflow: hidden;
  transition: all 0.2s;
}

.form {
  width: calc(100% - 250px);
  margin-left: auto;
  height: 100%;
  overflow: auto;

  &.hide-sidelist {
    width: calc(100% - 30px);
  }

  &.mb-4 {
    height: calc(100% - 70px);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $gray-700;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

// Menu toggle
.hide-icon {
  .menu-toggle {
    display: none;
  }
}

.container-body {
  height: calc(100vh - 180px);
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  border-left: 0;
  border-right: 0;
}

.content {
  position: relative;

  .card-wrapper {
    display: none;
    transition: all 0.2s;
    gap: 15px;

    .card {
      padding: 20px 12px;

      .card-body {
        gap: 10px;

        .card-img {
          padding: 5px;
        }
      }
    }
  }

  .mat-table-wrapper {
    height: calc(100vh - 180px);
  }

  &.hide-summery {
    .card-wrapper {
      display: flex;
      transition: all 0.2s;
    }

    .mat-table-wrapper {
      height: calc(100vh - 266px);
    }

    .summery-wrap {
      margin-top: 30px;
    }
  }

  &.hide-subtask {
    .card-wrapper {
      display: block;
    }
  }

  .summery-wrap {
    border-bottom: 1px solid rgba($black, 0.12);
    margin: 20px 0 30px 0;
    position: relative;

    .summery-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      height: 30px;
      width: 30px;
      margin: 0 auto;
      text-align: center;
      transition: all 0.2s;
      background-color: $white;
      box-shadow: 0 0 6px #00000040;
      cursor: pointer;

      .mat-icon {
        line-height: 32px;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .year-picker {
      .mat-form-field {
        width: 120px;
        margin: 0 10px;

        &.mat-form-field-type-mat-date-range-input {
          width: 220px;
        }

        .mat-form-field-infix {
          .mat-date-range-input-end-wrapper,
          .mat-date-range-input-start-wrapper {
            max-width: calc(50% - 4px);
          }
        }
      }
    }

    .content-header-input {
      display: flex;
      align-items: center;
      gap: 15px;

      .mat-form-field {
        width: 250px;
      }

      button.primary-button {
        line-height: 19px;

        .mat-icon {
          color: $white;
          margin-right: 5px;
          margin-left: -5px;
        }

        &:hover {
          .mat-icon {
            color: $white;
          }
        }
      }
    }

    .mat-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      color: $gray-100;
    }

    .content-right-button {
      .sidenav-content {
        display: flex;
        gap: 15px;
      }

      .mat-form-field {
        width: 110px;
      }

      .ng-select {
        width: 150px;
      }

      .toolbar {
        button[aria-expanded="true"] {
          outline: 1px solid $primary;
        }
      }
    }

    button.mat-button {
      box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
      border-radius: 6px;
      min-width: 40px;
      height: 40px;
    }
  }

  &.no-pagination,
  &.timesheet-content {
    padding-bottom: 20px;
  }

  // Calendar page design
  .k-scheduler-toolbar {
    background-color: transparent;
  }

  .k-button-solid-base {
    background-color: transparent;
    background-image: none;

    &:focus {
      box-shadow: none;
    }

    &.k-selected {
      border-color: $primary;
      background-color: $primary;
    }
  }

  .k-event {
    color: $gray-100;
    cursor: pointer;

    // background-color: $primary-200;
    .k-event-template {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
    }

    &.k-selected {
      box-shadow: none;
    }
  }

  .k-scheduler {
    .k-nonwork-hour {
      background-color: $white;
    }
  }
}

.search-field {
  .mat-icon {
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translate(-50%, -50%);
    color: $gray-100;
  }

  &.mini-search-field {
    padding: 6px 0 8px 0;

    &.mat-form-field {
      .mat-form-field-infix {
        padding: 0 30px 0 25px !important;
        width: 100%;
      }

      .mat-form-field-flex {
        background: $white;
        border-color: $border-color;
      }

      &.mat-focused {
        .mat-form-field-flex {
          border-color: $border-color;
        }
      }

      .mat-input-element::-webkit-input-placeholder {
        color: $gray-100;
      }
    }

    .mat-icon {
      left: 15px;
      color: $gray-100;

      &.close {
        left: auto;
      }
    }
  }
}

button.close-button {
  position: absolute;
  right: 10px;
  top: 5px;

  .mat-icon {
    color: $gray-300;
  }
}

.footer-list-action {
  button.button-icon {
    width: 75px;
    height: 75px;
    transition: all 0.2s;

    &:hover {
      background-color: $primary-300;
      transform: scale(1.1);

      .mat-icon {
        color: $primary !important;
      }

      p {
        color: $primary !important;
      }
    }

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
    }

    &:last-child {
      margin-right: 0;
    }

    p {
      margin: 8px 0 0;
      white-space: normal;
    }
  }
}

p {
  font-size: 14px;
  font-weight: normal;
}

.timepicker {
  &.day-week {
    .time-picker {
      position: relative;
      .timer-buttons {
        position: absolute;
        margin-left: 100px;
      }
    }
    .grid-actions {
      .mat-icon {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.mat-button.white-btn {
  background-color: $white;
  color: $primary-100;
  border: 1px solid rgba($black, 0.1);
  font-weight: 600;
  font-size: 16px;
  padding: 11px 20px;
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.text {
  &-lightgray {
    color: $lightgray;
  }

  &-red {
    color: $red;
  }

  &-muted {
    color: $gray-300;
  }
}

h2.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: $primary-100;
}

.sidenav-body {
  .no-margin {
    margin: 0;
  }
}

.settings-tab {
  .mat-tab-label {
    &:nth-child(11) {
      display: none;
    }
  }
}

.edit-form {
  .form-wrapper {
    width: 766px;
  }
}

.search-icon {
  img {
    margin-top: -4px;
  }
}

/* Checkbox */

mat-checkbox {
  padding: 5px 0;
  display: block;
  line-height: 16px !important;

  .mat-checkbox-inner-container {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .mat-checkbox-label {
    font-size: 14px;
    font-family: $fontFamily;
    font-weight: 400;
    color: $gray-100 !important;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .mat-checkbox-frame {
    border-color: rgba($black, 0.25);
    border-width: 2px;
    border-radius: 6px;
    color: $gray;
  }

  &:hover {
    .mat-checkbox-frame {
      border-color: $primary;
    }
  }

  &.mat-checkbox-checked.mat-accent {
    .mat-checkbox-background {
      background-color: $primary;
      border-radius: 6px;
    }
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent,
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent {
    .mat-ripple-element {
      background: $primary;
    }
  }

  &.mat-checkbox-checked {
    &.mat-checkbox-disabled {
      .mat-checkbox-background {
        background-color: $gray-700;
      }
    }
  }
  &.mat-checkbox-disabled {
    cursor: not-allowed;
    .mat-checkbox-background {
      background-color: $gray-700;
    }
  }
}

.add-task {
  mat-checkbox {
    .mat-checkbox-inner-container {
      margin-right: 27px;
    }
  }
}

/* Radio Button */

.mat-radio-button {
  .mat-radio-label {
    white-space: normal;
    align-items: flex-start;
  }

  .mat-radio-label-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 0;
    color: $gray-100 !important;
  }

  .mat-radio-container {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .mat-radio-outer-circle {
    width: 16px;
    height: 16px;
    border-color: rgba($black, 0.25);
  }

  &.mat-radio-disabled {
    .mat-radio-label {
      cursor: not-allowed;
    }
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: $gray-700;
      }
    }
    &.mat-accent.mat-radio-checked {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: $gray-700;
        }

        .mat-radio-inner-circle {
          background-color: $gray-700;
        }
      }
    }
  }

  &.mat-accent.mat-radio-checked {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-color: $primary;
      }

      .mat-radio-inner-circle {
        background-color: $primary;
        height: 16px;
        width: 16px;
      }
    }
  }

  .mat-radio-ripple {
    display: none;
  }
}

.send-tab-content {
  padding-bottom: 1px;

  .mat-radio-button {
    .mat-radio-label-content {
      padding-bottom: 0;
    }
  }
}

.contact-detail-table {
  .mat-radio-container {
    margin-right: 0;
  }
}

::-webkit-input-placeholder,
.process-steps {
  .mat-radio-button {
    &.mat-accent {
      &.mat-radio-checked {
        .mat-radio-container {
          .mat-radio-outer-circle {
            border-color: $purple-color;
          }

          .mat-radio-inner-circle {
            background-color: $purple-color;
          }
        }
      }
    }
  }
}

/* Mat Slide Toggle */

.mat-slide-toggle {
  &.mat-checked {
    .mat-slide-toggle-label {
      .mat-slide-toggle-bar {
        background-color: $primary;
      }

      .mat-slide-toggle-thumb {
        background-color: $white;
      }

      .mat-slide-toggle-thumb-container {
        left: 5px;
      }
    }
  }

  .mat-slide-toggle-bar {
    width: 40px;
    height: 20px;
    border-radius: 100px;
  }

  .mat-slide-toggle-thumb-container,
  .mat-slide-toggle-thumb {
    width: 18px;
    height: 18px;
  }

  .mat-slide-toggle-thumb-container {
    top: 1px;
    left: 1px;
  }
}

.main-contain {
  .assign-user {
    .mat-icon {
      width: 17px;
      height: 17px;
      font-size: 17px;
      line-height: 17px;
      color: $gray-300;
    }
  }

  .dashboard-wrapper {
    .ng-select {
      .ng-select-container {
        background-color: $white;
      }
    }
  }

  .timepicker-input {
    &.jobs-input {
      .ngx-timepicker {
        width: 118px;
      }
    }
  }

  .view-list {
    .mat-icon {
      width: 18px;
      height: 18px;
      font-size: 18px;
      line-height: 18px;
    }
  }

  .sidenav-body {
    &-content {
      padding: 15px 20px;
    }

    .form-field {
      margin-bottom: 15px;

      p {
        font-weight: 600;
        margin-bottom: 10px;
        color: $gray-100;

        img {
          margin-left: 10px;
          width: 20px;
          height: 20px;
        }
      }

      &.radio-button {
        align-items: flex-start;

        ul {
          p {
            font-size: 10px;
            font-weight: 400;
            margin-left: 38px;
            margin-bottom: 12px;
          }
        }
      }

      &.slide-toggle {
        justify-content: inherit;

        span {
          font-size: 14px;
        }
      }

      &.email-slide-toggle {
        display: flex;
        align-items: center;
      }

      .side-info {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
        }

        &-text {
          margin: 0 0 0 10px;
        }
      }

      &.toggle-option {
        button {
          border-radius: $border-radius;
          line-height: 34px;
          box-shadow: none;

          &.primary-button {
            &:hover {
              background-color: $primary-100;
              border-color: $primary-100;
              color: $white;
            }
          }

          &.secondary-button {
            &:hover {
              background-color: $primary-200;
              border-color: $primary;
              color: $primary;
            }
          }
        }
      }
    }

    .form-field-title {
      p {
        font-weight: 600;
        margin-bottom: 7px;
        font-size: 16px;
        color: $primary;
      }

      span {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }

  .send,
  .record {
    .form-field {
      .custom-textarea {
        width: 480px;
      }
    }

    .mat-form-field {
      width: 480px;
    }

    .ng-select {
      width: 480px;
    }
  }

  .notes {
    .mat-form-field {
      width: 550px;

      .mat-form-field-flex {
        height: 140px;
      }
    }
  }

  //File upload

  ngx-dropzone {
    max-width: 390px;
    border-color: rgb(204, 204, 204);
    min-height: 50px;
    height: auto;
    border-radius: 10px;
    padding: 20px;
    font-size: 14px;
    background-color: #f3f7fa;

    .mat-icon {
      width: 40px;
      height: 40px;
      font-size: 40px;
      line-height: 40px;
    }

    &-label {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .back-link {
    line-height: inherit;
    margin-bottom: 10px;

    .mat-icon {
      margin-right: 5px;
    }

    span {
      text-decoration: underline;
    }
  }

  // Time

  .timesheet-popup {
    .action-wrapper {
      left: 465px;
    }

    .form {
      padding-left: 465px;
    }
  }

  .accordion-toggle {
    color: $primary;
    background-color: $white;
    box-shadow: 0 0 6px rgba($black, 0.25);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 0 auto -15px auto;

    .mat-icon {
      line-height: 31px;
    }

    &:hover {
      background-color: $primary;

      .mat-icon {
        color: $white;
      }
    }
  }

  .timelog-list {
    .timepicker-input {
      .ngx-timepicker {
        width: 45px;
        height: 30px;
        border: 1px solid $primary;
        box-shadow: none;
      }
    }

    .overview-timelist {
      .timepicker-input {
        .ngx-timepicker {
          width: 40px;
          border: none;
        }
      }
    }

    .mat-table-wrapper {
      .week-list {
        margin: 0 auto;
        max-width: 40px;
        // &.dayview-list {
        //   position: relative;
        //   right: 16px;
        // }
      }

      .mat-form-field {
        .mat-form-field-flex {
          border: 1px solid #6661b8;
          border-radius: 4px;
          box-shadow: none;
          height: 30px;
          margin-top: 0;

          .mat-form-field-infix {
            height: 30px;

            .mat-input-element {
              height: 30px;
            }
          }
        }
      }

      .mat-row {
        .mat-cell {
          padding: 7px 12px;
        }
      }

      .space {
        padding-right: 30px;
      }
    }

    .month {
      color: $gray-300;
    }

    .timer-start {
      border-right: 1px solid $gray-300;
      padding-right: 10px;
      margin-top: 0;
      height: 30px;
      line-height: 30px;
    }

    .timer-button {
      button.mat-button {
        line-height: 30px;
      }
    }

    .timelist-view {
      .time-title {
        img {
          margin-top: -4px;
        }

        .dot {
          height: 8px;
          width: 8px;
          background-color: $success-100;
          border-radius: 100%;
          display: inline-block;
        }

        .mat-icon {
          width: 16px;
          height: 16px;
          font-size: 15px;
          line-height: 20px;
          color: $success-100;
        }
      }

      .curency-icon {
        background-color: $primary;
        color: $white;
        padding: 2px;
        border-radius: 2px;
        width: 14px;
        height: 14px;
        font-size: 12px;
        line-height: 14px;
        position: relative;
        top: 4px;
      }

      .startend-time {
        position: relative;
        color: $gray-100;
        padding-right: 6px;

        &:after {
          content: "";
          position: absolute;
          top: 12px;
          height: 20px;
          background-color: $gray-300;
          right: -5px;
          width: 1px;
        }
      }

      .time-coment {
        border-left: 1px solid $gray-300;
        padding-left: 6px;

        .mat-icon {
          width: 18px;
          height: 18px;
          font-size: 18px;
          line-height: 18px;
          padding-top: 5px;

          &.comment {
            position: relative;
            overflow: inherit;

            &::after {
              content: "";
              position: absolute;
              top: -5px;
              right: -6px;
              width: 6px;
              height: 6px;
              background-color: $success-100;
              border-radius: 100px;
              display: block;
            }
          }
        }
      }
    }
  }

  .comingsoon {
    height: calc(100vh - 130px);
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      font-weight: 600;
      color: $gray-100;
      margin: 10px 0 5px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: $gray-100;
      margin-bottom: 0;
    }
  }
  .timelog-details {
    .timelist-view {
      padding: 12px 0;
      border-bottom: 1px dashed #d4d4d4;
      .startend-time {
        &:after {
          top: 5px;
          height: 30px;
        }
      }
      .time-coment {
        position: relative;
        border: none;
        &::after {
          content: "";
          position: absolute;
          top: 12px;
          height: 20px;
          background-color: $gray-300;
          left: -5px;
          width: 1px;
        }
      }
    }
  }

  // disable mode
  .is-readonly {
    .form-field {
      .attachFile {
        background-color: $gray;
      }
    }
    .mat-form-field {
      .mat-form-field-flex {
        background-color: $gray;
        box-shadow: 0px 0px 4px 0px rgba($primary, 0.12);
      }
    }
    .ng-select {
      .ng-select-container {
        background-color: $gray;
        box-shadow: 0px 0px 4px 0px rgba($primary, 0.12);
      }
    }
  }

  .dash-dropdown-list {
    .mat-form-field {
      .mat-form-field-flex {
        height: 35px;
      }
      .mat-form-field-infix {
        height: 35px;
      }
      .mat-select {
        line-height: 35px;
        height: 35px;
      }
    }
  }
}

.upload-wrapper {
  ngx-dropzone {
    border: 0;
    background-color: transparent;
    max-width: auto;
    padding: 0;

    .mat-icon {
      display: block;
      margin: 0 auto;
    }

    &-label {
      display: block;
    }
  }
}

// Import & Export

.data {
  &-title {
    margin-top: 8px;
  }

  &-icon {
    button {
      background-color: $white;
      border-radius: 50%;
      width: 55px;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 2px 2px 0px 1px $primary;
      border: 1px solid $primary;
      margin: 0 auto;

      &:hover {
        border: 1px solid $primary;
        box-shadow: 2px 2px 0px 1px $white;
        background-color: $primary;
        border-color: $primary;

        .mat-icon {
          color: $white;
        }
      }
    }

    .mat-icon {
      width: 30px;
      height: 30px;
      font-size: 30px;
      line-height: 30px;
    }
  }
}

//  Progress bar

.mat-progress-bar {
  .mat-progress-bar-fill {
    &::after {
      background: linear-gradient(270deg, $primary 0%, $primary-200 100%);
      border-radius: 50px;
    }
  }

  .mat-progress-bar-buffer {
    background-color: $gray-700;
  }
}

// Scroll Bar

::-webkit-scrollbar-thumb {
  background-color: $gray-700;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: transparent;
}

// Datepicker css
.mar-daterange-arrow {
  position: absolute;
  top: 9px;
  right: 0;

  button {
    border: none;
    background: transparent;
    padding: 0;
  }

  .mat-icon {
    cursor: pointer;
  }
}

.mat-calendar-body {
  &-selected {
    background-color: $primary-100;
  }

  &-in-range::before {
    background: $gray;
  }
}

.tab-pages {
  &.add-page {
    overflow: hidden;
    height: calc(100vh - 56px);

    .form.mb-4 {
      height: calc(100% - 155px);
    }
  }

  .mat-tab-label-content {
    width: 100%;
    justify-content: left !important;
  }
}

.tab-pages.expense {
  .action-wrapper {
    left: auto;
  }
}

.note-table {
  .table-border {
    width: 100%;
    border: 1px solid $lightgray;
    border-radius: 5px;
    border-spacing: 0;

    .clickable {
      border-bottom: 1px solid $lightgray;

      &:last-child {
        border-bottom: none;
      }

      div {
        font-size: 14px;
        color: $gray-100;
        padding: 2px 10px;
      }
    }

    .mat-icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 20px;
    }
  }
}

.edit-date {
  .mat-form-field-suffix {
    .mat-icon-button {
      width: 2.5em;
    }
  }
}

// Payment strip

.border-box {
  background-color: $primary-200;
  border: 1px solid $primary;
  align-items: center;
  border-radius: $border-radius;
  padding: 20px;

  p {
    background-color: transparent;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-content {
    display: flex;
  }
}

.form-field {
  p {
    font-weight: 600;
  }

  .notes-message {
    font-size: 12px;
    font-family: $fontFamily;
    font-weight: 400;
    padding-top: 5px;
    letter-spacing: 0.3px;
  }

  .angular-editor-toolbar {
    background-color: transparent !important;
    border: 0 !important;
    width: 100%;
    padding: 0;
    position: absolute;
    bottom: 0;
    height: 25px;

    .angular-editor-toolbar-set {
      margin-right: 4px !important;

      .angular-editor-button {
        border: 0 !important;
        padding: 0 !important;
        min-width: 25px !important;
        background-color: transparent !important;

        &:hover {
          background-color: transparent !important;
        }
      }

      .ae-picker-label {
        border: 0;
        line-height: inherit;
      }
    }
  }

  .angular-editor {
    width: 500px !important;
  }

  angular-editor {
    .angular-editor {
      .angular-editor-wrapper {
        .angular-editor-textarea {
          height: 250px !important;
          border: 0 !important;
          box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
          border-radius: 5px;
          resize: inherit;
          z-index: -1;
          font-size: 14px;
          position: unset !important;

          &:focus-visible {
            outline: none;
          }

          ul,
          ol {
            padding-left: 10px;
          }

          ul {
            li {
              list-style: disc;
            }
          }

          ol {
            li {
              list-style: inherit;
            }
          }
        }

        table {
          width: 100% !important;
        }
      }
    }
  }
}

.mat-tab-group {
  .mat-tab-label {
    &.mat-tab-label-active {
      .count {
        display: inline-block;
      }
    }
  }

  &.email-template {
    .mat-tab-labels {
      .mat-tab-label {
        &:last-child {
          display: block;
        }
      }
    }
  }
}

.send-box {
  padding: 15px;
  background-color: $white;
  border: 1px solid $border-color;
  box-sizing: border-box;
  border-radius: $border-radius;
  margin: 20px 0;
  width: 840px;

  .send-box-details {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
      font-size: 18px;
    }

    span {
      font-size: 12px;
    }
  }

  .mat-radio-button {
    .mat-radio-label {
      justify-content: center;
    }
  }
}

.account-box {
  background-color: $white;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  padding: 16px 0 16px 0;
  margin-top: 16px;

  .user-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    &:hover {
      box-shadow: 0 6px 4px -4px rgba($black, 0.08),
        0 -6px 4px -4px rgba($black, 0.08);

      .account-detail {
        opacity: 1;
      }
    }

    .mat-radio-button {
      .mat-radio-container {
        margin-right: 0;
      }
    }
  }

  .account-detail {
    opacity: 0;
    transition: all 0.2s;

    .success-icon {
      font-size: 12px;
      width: 12px;
      height: 12px;
      line-height: 12px;
    }
  }
}

.add-account {
  button {
    .mat-icon {
      width: 30px;
      height: 30px;
      font-size: 30px;
      line-height: 30px;
      margin-right: 10px;
    }
  }
}

// coming soon css

.coming-soon {
  img {
    max-width: 100%;
    width: 500px;
    margin: auto;
    display: block;
  }
}

.email-template-wrapper {
  .input-container {
    .backdrop,
    input,
    .custom-textarea,
    .custom-input {
      width: 500px;
    }
  }
}

.roles-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 500px;
  gap: 10px;

  button {
    &.secondary-button {
      &:hover {
        color: $primary;
        border-color: $primary;
      }

      &.cdk-selection-selected {
        color: $white;
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}

// Invoice and Recurring Invoice

.is-readonly {
  .mat-expansion-panel-content {
    z-index: -1;
  }
}

.reminders-payment {
  display: flex;

  li {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-textarea {
  display: inline-block;
  box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
  border-radius: 6px;
  width: 500px;

  table {
    tr {
      text-align: left;
    }
  }

  &:focus-visible {
    outline: 1px solid $primary;
  }
}

// no record found css
.no-record {
  svg {
    width: 250px;
    height: 250px;

    .addfill {
      fill: $primary;
    }
  }

  &-img {
    max-height: 350px;
  }

  .mat-button {
    .mat-button-wrapper {
      span {
        margin: 0 8px;
      }
    }

    &.cdk-keyboard-focused {
      box-shadow: 0 2px 6px 1px rgba($black, 0.5);
    }
  }

  &.content {
    height: calc(100vh - 207px);
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border-radius: $border-radius;

      &.primary-button {
        &:hover {
          .mat-icon {
            color: $white;
          }
        }
      }
    }
  }
}

.archive-records {
  .copy-archive-button {
    display: none;
  }
}

.restore-records {
  .restore-button {
    display: none;
  }
}

.project {
  app-pending-project {
    .mat-table-wrapper {
      max-height: calc(100vh - 299px);
      height: auto;
    }
  }

  .mat-tab-group {
    .mat-tab-header {
      top: 0;
    }
  }
}

.analysis {
  &-box {
    border-radius: $border-radius;
    background: $white;
    box-shadow: $box-shadow-100;

    &.gradient {
      &-purple {
        .mat-progress-bar-fill {
          &::after {
            background: linear-gradient(270deg, $primary 0%, $primary-200 100%);
          }
        }
      }

      &-pink {
        .mat-progress-bar-fill {
          &::after {
            background: linear-gradient(270deg, $primary 0%, $primary-200 100%);
          }
        }
      }

      &-yellow {
        .mat-progress-bar-fill {
          &::after {
            background: linear-gradient(270deg, $primary 0%, $primary-200 100%);
          }
        }
      }
    }

    .mat-progress-bar {
      height: 16px;

      .mat-progress-bar-background {
        fill: $gray-700;
      }

      .mat-progress-bar-fill {
        &::after {
          border-radius: 0 50px 50px 0;
        }
      }
    }
  }

  &-content {
    padding: 15px;
  }

  &-detail {
    flex: 1;
  }
}

.analysis-wrap {
  position: relative;

  .analysis-btn {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1;
    display: flex;
    gap: 15px;
    button.mat-button {
      box-shadow: 0px 0px 5px 1px rgba($black, 0.12);
      border-radius: 6px;
      min-width: 40px;
      line-height: 40px;
      height: 40px;
    }
  }
}

.process-content {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.form-list-content {
  padding: 15px 20px;

  .form-field {
    &:last-child {
      margin-bottom: 15px !important;
    }

    .custom-textarea {
      width: 295px;
      height: 200px;
      overflow: auto;

      tr {
        td {
          word-break: break-word;
        }
      }
    }
  }

  .mat-radio-button {
    .mat-radio-label-content {
      line-height: 17px;
      padding-left: 0;
    }
  }

  .sidenav-header {
    border-bottom: 1px solid $gray-800;
    padding-bottom: 15px;

    h2 {
      padding: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 600;
      color: $primary;
      line-height: normal;
    }

    .header-text-close {
      line-height: 24px;
    }

    .mat-icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      color: $gray-300;
      cursor: pointer;
    }
  }
}

.main-list {
  .form-list-content {
    height: calc(100vh - 156px);
    overflow: auto;
  }

  .action-wrapper {
    width: 100%;
  }
}

.content-main-box {
  flex: auto;
}

.sidenav-body-content {
  .content-box {
    padding: 15px 0;
  }
  .business-form {
    display: inline-grid;
    grid-template-columns: auto auto;
    gap: 0 20px;
  }
  .mat-icon-button {
    .mat-icon {
      line-height: 18px;
    }
  }
}

.check-list {
  ul {
    flex-wrap: wrap;

    li {
      width: 192px;

      .mat-checkbox-label {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

// Dashboard design
.widget-wrap {
  .widget-tab {
    .mat-tab-header {
      margin-right: 155px;
    }
  }
}

.widget-hide {
  display: none;
}

.widget-box {
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  .card {
    width: 653px;
    margin: 0 auto;
    padding: 80px 0;
  }

  .widget-button {
    button.mat-button {
      border-radius: 6px;
    }
  }
}

.add-widget-box {
  width: 550px;
  margin: 0 auto;

  .card {
    padding: 10px 15px;

    .title {
      border-color: $gray-800;
      margin-bottom: 5px;

      h2 {
        font-size: 16px;
        font-weight: 600;
        color: $primary;
        margin-bottom: 0;
        line-height: 22px;
      }
    }

    .mat-checkbox-label {
      img {
        padding-right: 5px;
        margin-top: -3px;
      }
    }
  }

  .wlist-box {
    // display: grid;
    // grid-template-columns: auto auto;
    .mat-checkbox {
      display: block;
      padding: 2px 0;
    }
  }

  ul {
    li {
      img {
        padding-right: 5px;
        margin-top: -3px;
      }
    }

    &:first-child {
      padding-right: 10px;
    }
  }

  .widget-button {
    button.mat-button {
      border-radius: 6px;
    }
  }
}

.dash-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;

  .dash-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;

    .card {
      margin: 10px 13px;
    }
  }

  .dash-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;

    .card {
      margin: 10px;
    }
  }

  .dash-col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66%;
    flex: 0 0 66.66%;
    max-width: 66.66%;

    .card {
      margin: 10px;
    }
  }

  .dash-col-full {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;

    .card {
      margin: 10px;
    }
  }
}

.dashtable-wrapper {
  .card-body {
    display: block;
    height: 296px;
    overflow: auto;
    overflow-x: hidden;
  }

  .card-header {
    .yearpicker {
      mat-datepicker-toggle {
        padding-right: 10px;

        .mat-button-wrapper {
          svg {
            padding: 0 8px 0 0px;
            color: $gray-100;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }

  .mat-table {
    .mat-row {
      &:hover {
        box-shadow: none;
      }
    }

    .mat-header-row {
      .mat-header-cell {
        padding: 0 8px;
      }
    }
  }

  .add-widget {
    height: 352px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: none;
    }

    .mat-icon {
      color: $gray-800;
      width: 45px;
      height: 45px;
      font-size: 45px;
      line-height: 45px;
    }
  }

  .card-title {
    @include font-style($fontFamily, $primary, 16px, 600, 17px);
    padding-right: 10px;
  }

  .list-body {
    padding: 13px 20px;

    h3 {
      @include font-style($fontFamily, $gray-100, 18px, 600, 17px);
      margin-bottom: 5px;
    }

    p {
      @include font-style($fontFamily, $gray-100, 24px, 600, normal);
      margin-bottom: 0;

      &.success {
        color: $success-100;
      }

      &.danger {
        color: $danger-100;
      }

      &.yellow {
        color: $yellow-200;
      }

      &.blue {
        color: $blue-color;
      }
    }
  }

  .card-list {
    form {
      padding-left: 10px;

      mat-form-field {
        width: 265px;

        .mat-form-field-infix {
          padding-left: 0;
        }

        // padding-right: 10px;
        .mar-daterange-arrow {
          .mat-icon {
            display: block;
            width: 15px;
            height: 15px;
            line-height: 20px;
          }
        }
      }
    }

    mat-icon {
      padding: 0 5px;
      margin-left: 10px;
      cursor: pointer;
    }

    &.daterangepicker {
      mat-icon {
        padding: 0;
        margin-left: 0;
      }

      form {
        mat-form-field {
          .mar-daterange-arrow {
            .mat-icon {
              width: 20px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .reminder-button {
    button.mat-button {
      margin-right: 0;

      .mat-icon {
        padding-right: 5px;
      }
    }
  }

  .status-wrapper {
    .status-card {
      box-shadow: 0px 0px 4px 1px rgba($black, 0.1);
      border-radius: 14px;
      background-color: $white;

      .status-head {
        padding: 10px;
        border-radius: 14px 14px 0 0;

        &.draft {
          background-color: #fff6d0;

          span {
            background: #edc000;
          }
        }

        &.approve {
          background-color: #d8ebfe;

          span {
            background: #5894e0;
          }
        }

        &.pfa {
          background-color: #ffebda;

          span {
            background: #dd7116;
          }
        }

        &.unsubmitted {
          background-color: #ffd8e2;

          span {
            background: #f15c80;
          }
        }

        span {
          border-radius: 4px;
          padding: 0 5px;
          font-size: 12px;
          color: $white;
          margin-left: 10px;
        }
      }

      .status-body {
        padding: 10px;
      }
    }

    .calender-list {
      border-right: 1px solid $gray-800;
      width: 33.33%;

      &:last-child {
        border-right: none;
      }

      h2 {
        @include font-style($fontFamily, $gray-100, 12px, 600, 17px);
        margin-bottom: 10px;
      }

      p {
        @include font-style($fontFamily, $danger-100, 16px, 600, 17px);
        margin-bottom: 0;
      }
    }
  }

  .apexcharts-canvas {
    margin: 0 auto;
  }

  .progress-table {
    .mat-table {
      tr {
        th:first-child {
          width: 300px;
        }
      }
    }

    .flex-column {
      &.success {
        border-left: 3px solid $primary;
        padding-left: 5px;

        p {
          &.subname {
            color: $primary;
            position: relative;
            padding-left: 10px;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 7px;
              height: 7px;
              width: 7px;
              border-radius: 25px;
              background-color: $primary;
            }
          }
        }
      }

      &.yellow {
        border-left: 4px solid $primary;
        padding-left: 5px;

        p {
          &.subname {
            color: $primary;
            position: relative;
            padding-left: 10px;

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 7px;
              height: 7px;
              width: 7px;
              border-radius: 25px;
              background-color: $primary;
            }
          }
        }
      }
    }

    .team-title {
      white-space: nowrap;
    }

    .mat-progress-bar {
      height: 20px;

      .mat-progress-bar-background {
        fill: none;
      }

      .mat-progress-bar-buffer {
        background-color: $gray-A100;
        border-radius: 14px;
      }

      .mat-progress-bar-fill {
        &::after {
          border-radius: 14px;
          background: $primary;
        }
      }

      &.success {
        .mat-progress-bar-fill {
          &::after {
            background: $primary;
          }
        }
      }

      &.yellow {
        .mat-progress-bar-fill {
          &::after {
            background: $primary;
          }
        }
      }
    }
  }

  .status-overdue,
  .status-due,
  .status-firstdue,
  .status-middledue,
  .status-lastdue {
    span {
      color: $white;
      display: inline-block;
      text-align: center;
      border-radius: 4px;
      padding: 5px 0;
      width: 60px;
    }
  }

  .status-overdue {
    span {
      background-color: $primary;
    }
  }

  .status-due {
    span {
      background-color: $primary;
    }
  }

  .status-firstdue {
    span {
      background-color: $primary;
    }
  }

  .status-middledue {
    span {
      background-color: $primary;
    }
  }

  .status-lastdue {
    span {
      background-color: $primary;
    }
  }
}

.norecord-chart {
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.graphical-content {
  .card-header {
    border-bottom: 1px solid $gray-800;
    margin-bottom: 20px;
  }

  #chart {
    height: 280px;
  }

  .norecord-chart {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    // display: none;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.statistical-content {
  .mat-table {
    table-layout: inherit;
  }
}

// Import update design css
.receipt-hide {
  display: none;
}

.import-head {
  padding-bottom: 10px;

  p {
    @include font-style($fontFamily, $primary, 20px, 600, 18px);
    margin-bottom: 0;
  }

  .mat-button {
    line-height: normal;

    .highlight-icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      line-height: 25px;
    }
  }
}

.import-title {
  @include font-style($fontFamily, $gray-100, 16px, 600, 18px);
  margin: 15px 0;
  display: inline-block;
}

.import-wrapper {
  // width: 760px;
  .addfill {
    fill: $primary;
  }

  .addstroke {
    stroke: $primary;
  }

  .import-card {
    display: inline-block;
    width: 135px;
    background-color: $white;
    box-shadow: -10px -10px 20px $white, 10px 10px 30px rgba($gray-500, 0.4);
    box-shadow: 0px 0px 5px 1px rgba($gray-500, 0.4);
    border-left: 4px solid $primary;
    border-radius: 6px;
    padding: 25px 10px 25px 12px;
    margin: 10px 20px 10px 0;
    position: relative;

    .import-body {
      gap: 10px;

      .import-img {
        display: flex;
        text-align: center;

        .mat-icon {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 22px;
          border: 1px solid $primary;
          color: $primary;
          border-radius: 50%;
          padding: 7px;
        }
      }
    }

    .mat-checkbox {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 0;

      .mat-checkbox-frame {
        border: 1px solid $primary;
      }

      .mat-checkbox-inner-container {
        margin: 0;
      }
    }

    .mat-radio-button {
      position: absolute;
      right: 10px;
      top: 10px;

      .mat-radio-outer-circle {
        border: 1px solid $primary;
      }

      .mat-radio-container {
        margin: 0;
      }
    }

    &.active {
      background-color: $primary-200;

      .import-body {
        .import-img {
          .mat-icon {
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }

  .import-text {
    h2 {
      @include font-style($fontFamily, $primary, 14px, 600, 18px);
      margin: 0;
    }

    p {
      @include font-style($fontFamily, $gray-100, 12px, 400, 15px);
      margin: 0;
    }
  }
}

.import-stepper {
  .mat-step-header {
    .mat-step-icon {
      width: 42px;
      height: 42px;
      border: 1.5px solid $primary;
      background: transparent;
      color: $primary;

      &.mat-step-icon-state-edit {
        background-color: $primary;
        color: $white;

        &::before {
          content: "\e876";
          font-family: "Material Icons", sans-serif;
          font-size: 24px;
          position: absolute;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.mat-step-icon-selected {
        background-color: $primary;
        color: $white;
      }

      .mat-step-icon-content {
        span {
          display: block;
        }

        &::before {
          content: none;
        }
      }
    }
  }

  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      width: 760px;
    }

    .mat-stepper-horizontal-line {
      margin: -28px -10px 0;
      border-top: 3px solid $primary;
    }
  }

  .import-duplicate {
    h2 {
      @include font-style($fontFamily, $gray-100, 14px, 600, 18px);
    }
  }

  .mat-horizontal-content-container {
    padding: 0 0 10px 0;
    overflow: unset;
  }

  .mat-table-wrapper {
    overflow-x: auto;

    .mat-table {
      table-layout: inherit;

      .mat-header-cell {
        white-space: nowrap;
      }

      .clamp {
        max-width: 170px;
      }

      .mat-form-field,
      .ng-select {
        width: 160px;
      }
    }
  }
}

.fetch-data {
  width: 500px;

  .mat-stepper-horizontal {
    .mat-step-header {
      .mat-step-icon {
        background-color: $success-100;
        border: 1px solid $success-100;
        margin: 0;
      }

      .mat-step-icon-state-edit {
        background-color: $success-100;
        border: 1px solid $success-100;
      }

      .mat-step-icon-selected {
        background-color: rgba($success, 0.5);
      }
    }

    .mat-stepper-horizontal-line {
      border-top: 7px solid $success-100;
      margin: -30px -46px 0 -46px;
    }

    .mat-step-icon-content {
      &::before {
        content: none;
      }
    }
  }
}

.import-leads {
  display: flex;
  gap: 20px;

  .or-text {
    display: flex;
    align-items: center;
    position: relative;
    @include font-style($fontFamily, $gray-100, 16px, 600, 18px);

    span {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      background: $white;
      padding: 15px;
      z-index: 0;
    }

    &::before {
      content: "";
      position: absolute;
      height: 35%;
      background: $gray-800;
      left: 0;
      right: 0;
      top: auto;
      width: 2px;
      margin: 0 auto;
      z-index: 0;
    }
  }

  .file-box {
    padding: 17px;
    border: 1px dashed $gray-100;
    border-radius: 10px;
    width: 320px;

    p {
      @include font-style($fontFamily, $gray-100, 14px, 600, 18px);
    }
  }

  .other-box {
    padding: 20px;
    border: 1px solid $gray-100;
    border-radius: 10px;
    width: auto;

    p {
      @include font-style($fontFamily, $gray-100, 14px, 600, 18px);
    }
  }

  .drop-detail {
    text-align: center;
    padding-top: 25px;

    .file-info {
      background: $primary-200;
      border-radius: 5px;
      padding: 0 10px;
      width: 150px;
      margin: 10px auto;
      word-break: break-word;
      @include font-style($fontFamily, $primary, 14px, 400, 24px);
    }

    p {
      @include font-style($fontFamily, $gray-100, 14px, 400, 18px);
      margin-bottom: 20px;
    }

    h4 {
      @include font-style($fontFamily, $gray-100, 16px, 600, 18px);
      margin-bottom: 20px;
      position: relative;

      span {
        position: relative;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        height: 2px;
        background: $gray-800;
        top: 10px;
        right: 80px;
        width: 60px;
        margin: 0 auto;
        z-index: 0;
      }

      &::before {
        content: "";
        position: absolute;
        height: 2px;
        background: $gray-800;
        left: 80px;
        top: 10px;
        width: 60px;
        margin: 0 auto;
        z-index: 0;
      }
    }

    .file-formate {
      margin-top: 10px;

      p {
        @include font-style($fontFamily, $gray-100, 14px, 400, 18px);
        margin-bottom: 5px;
      }
    }
  }

  .connect-wraper {
    text-align: center;

    .connect-details {
      width: 195px;
      margin: 0 auto;
      padding: 12px;
      background-color: $primary-200;
      border: 1px solid $primary;
      border-radius: 10px;

      p {
        @include font-style($fontFamily, $primary, 14px, 400, 16px);
        margin-bottom: 0;
      }
    }

    .connect-button {
      position: relative;
      padding-top: 25px;

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        height: 25px;
        width: 2px;
        border-left: 1px dashed #4461d7;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 0;
      }
    }
  }
}

.import-duplicate {
  .mat-radio-button {
    margin-bottom: 30px;
  }

  .import-radio {
    display: flex;

    h4 {
      @include font-style($fontFamily, $gray-100, 16px, 600, 18px);
      margin-bottom: 0;
      width: 100px;
    }

    p {
      @include font-style($fontFamily, $gray-100, 14px, 400, 18px);
      margin-bottom: 0;
    }
  }
}

.map-field-form {
  .form-field {
    p {
      width: 350px;
    }
  }
}

.list-detail {
  width: 760px;
  margin-top: 20px;

  p {
    border-bottom: 1px solid $gray-800;
    padding-bottom: 12px;
    font-weight: 600;
  }
}

.remove-list {
  .ng-arrow-wrapper {
    display: none;
  }
}

.teammates-detail,
.task-details {
  .form {
    width: 100%;
  }
}

.grid-list {
  border-radius: 5px;
  margin: 3px 0;

  ul {
    li {
      padding: 0 3px;
      border-right: 1px solid $primary;
      white-space: nowrap;
      line-height: normal;

      &:last-child {
        border-right: none;
      }

      .mat-icon {
        width: 20px;
        height: 17px;
        font-size: 17px;
        line-height: 17px;
      }
    }
  }

  .contractor {
    background-color: $primary-200;
    padding: 6px;
    border-radius: 5px 5px 0 0;
    color: $primary;
  }

  .employee {
    padding: 6px;
    color: $gray-100;
  }
}

// Loader
.loader-image {
  width: 40px;
  height: 40px;
}

.loader {
  text-align: center;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: block;
  margin: 4px auto;
  position: relative;
  color: $white;
  left: -110px;
  box-sizing: border-box;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {
    box-shadow: 0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  12% {
    box-shadow: 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0), 0px 0 rgba(255, 255, 255, 0);
  }

  25% {
    box-shadow: 110px 0 white, 100px 0 white, 0px 0 rgba(255, 255, 255, 0),
      0px 0 rgba(255, 255, 255, 0);
  }

  36% {
    box-shadow: 120px 0 white, 110px 0 white, 100px 0 white,
      0px 0 rgba(255, 255, 255, 0);
  }

  50% {
    box-shadow: 130px 0 white, 120px 0 white, 110px 0 white, 100px 0 white;
  }

  62% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 130px 0 white, 120px 0 white,
      110px 0 white;
  }

  75% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      130px 0 white, 120px 0 white;
  }

  87% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 130px 0 white;
  }

  100% {
    box-shadow: 200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0),
      200px 0 rgba(255, 255, 255, 0), 200px 0 rgba(255, 255, 255, 0);
  }
}

// hide sidemenu css
.sidemenu-hide {
  height: calc(100vh - 56px);
  overflow: hidden;
  transition: all 0.2s;

  // .form {
  //   margin-left: -30px;
  // }
  .menu-toggle {
    left: 230px;

    &.toggle-icon {
      left: 13px;
    }
  }

  .action-wrapper {
    width: 100%;
  }

  .hide-sidelist {
    .action-wrapper {
      width: 100%;
    }
  }
}

.invite-list {
  .side-list-actions {
    &.footer-list-action {
      left: 0;
      width: 100%;
    }
  }

  .pagination-toggle {
    margin-left: 0 !important;
  }

  .paginator-container {
    left: 0 !important;
    width: calc(100% - 50px) !important;
  }
}

.success {
  color: $success-100;
}

.danger {
  color: $red !important;
}

.hm-time {
  .mat-form-field-suffix {
    padding: 7px 10px;
    border-left: 1px solid $gray-A100;
  }
}

// Job details page
.job-detail-card {
  .job-wrapper {
    .job-card {
      background-color: $primary-200;
      box-shadow: -10px -10px 20px $white, 10px 10px 30px rgba($gray-500, 0.4);
      box-shadow: 0px 0px 5px 1px rgba($gray-500, 0.4);
      border-left: 4px solid $primary;
      padding: 15px 10px;
      margin: 20px 0;

      p {
        font-size: 16px;
        font-weight: 400;
        color: $primary;
      }

      .mat-icon {
        width: 19px;
        height: 19px;
        font-size: 19px;
        line-height: 18px;
        padding-right: 5px;
        &.success {
          background-color: #eafff4;
          text-align: center;
          padding: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 100px;
          font-size: 14px;
          margin-right: 5px;
        }
        &.danger {
          background-color: #ffeeea;
          text-align: center;
          padding: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 100px;
          font-size: 14px;
          margin-right: 5px;
        }
      }

      .job-timer {
        button {
          &.secondary-button {
            line-height: 28px;
            padding: 0 10px;

            .mat-icon {
              width: 17px;
              height: 17px;
              font-size: 17px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

.timelog-box {
  padding: 15px;
  border: 1px solid #b6bfca;
  border-radius: 6px;
}

.change-type {
  .mat-radio-button {
    .mat-radio-container {
      margin-top: 10px;
    }
  }

  .mat-icon {
    width: 15px !important;
  }

  .mat-form-field {
    .mat-form-field-infix {
      padding: 0 5px !important;
    }
  }
}

.job-detail-tab {
  .mat-tab-group {
    .mat-tab-header {
      padding: 0;

      .mat-tab-label {
        padding: 0 2px;
        margin-right: 20px;

        .mat-tab-label-content {
          span {
            background-color: $gray-700;
            color: $gray-100;
            width: 19px;
            height: 19px;
            line-height: 19px;
            margin: 0 auto;
            text-align: center;
            border-radius: 100px;
            font-size: 12px;
            position: absolute;
            right: -10px;
            top: -1px;
          }

          .dot {
            height: 8px;
            width: 8px;
            background-color: $success-100;
            border-radius: 100%;
            display: inline-block;
            right: -5px;
            top: 10px;
          }
        }

        &.mat-tab-label-active {
          .mat-tab-label-content {
            span {
              background-color: $primary;
              color: $white;
            }

            .dot {
              background-color: $success-100;
            }
          }
        }
      }
    }
  }

  .sidenav-body-content {
    padding-left: 0;
    padding-right: 10px;
  }
}

.timelog-popup {
  .mat-dialog-content {
    width: 750px;
    max-height: 90vh;
  }
  .mat-input-element[type="time"] {
    &::after {
      content: none;
    }
  }
}

.ticket-popup {
  .mat-dialog-content {
    width: 756px;
    max-height: 100vh;
  }
}

.overview-wrap {
  box-shadow: 0px 0px 10px rgba($black, 0.1);
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;

  p {
    font-weight: 400;
    font-size: 16px;
    color: $gray-100;
    border-bottom: 1px solid $gray-800;
    padding-bottom: 15px;
  }
}

.timesheet {
  &.mat-dialog-content {
    // overflow: hidden;
    max-height: 77vh;
  }
}

app-jobs-list {
  .mat-table-wrapper {
    .mat-table {
      tr {
        th:first-child {
          padding-right: 0;
        }

        td:first-child {
          padding-right: 0;
        }
      }
    }
  }
}

.client-progress {
  .client-progress-list {
    // overflow: auto;
    // height: 177px;
  }

  .mat-progress-bar {
    height: 12px;
    border-radius: 0;

    .mat-progress-bar-background {
      fill: none;
    }

    .mat-progress-bar-buffer {
      background-color: $gray-A100;
      border-radius: 0;
    }

    .mat-progress-bar-fill {
      &::after {
        border-radius: 0;
        background: $primary;
      }
    }

    &.purple-clr {
      .mat-progress-bar-fill {
        &::after {
          background: #7a5295;
        }
      }
    }

    &.red-clr {
      .mat-progress-bar-fill {
        &::after {
          background: #ba5397;
        }
      }
    }

    &.orange-clr {
      .mat-progress-bar-fill {
        &::after {
          background: #f2816f;
        }
      }
    }

    &.success {
      .mat-progress-bar-fill {
        &::after {
          background: $primary;
        }
      }
    }

    &.yellow {
      .mat-progress-bar-fill {
        &::after {
          background: $primary;
        }
      }
    }
  }
}

.timelog-overview {
  .mat-table {
    .mat-footer-row {
      background-color: $white;
      color: $gray-100;

      .mat-footer-cell {
        color: $gray-100;
        font-weight: 600;
      }
    }

    .mat-row {
      .mat-cell {
        padding: 5px 12px;
      }
    }
  }
}

.roi-detail {
  background-color: $primary-200;
  border-radius: 5px;
  padding: 10px;
  position: relative;

  .roi-detail-icon {
    position: absolute;
    right: -8px;
    top: -12px;
  }
}

.jb-list {
  font-size: 14px;
  color: $gray-100;
  display: flex;
  align-items: center;
  gap: 50px;

  span {
    width: 15px;
    height: 15px;
    background-color: $primary;
    display: block;

    &.gray {
      background-color: $gray-800;
    }
  }
}

.activity-menu {
  &.mat-menu-panel {
    max-width: 400px;
  }
}

.header-activity {
  .notification {
    padding: 15px;

    .notification-list {
      li {
        margin-bottom: 0;
      }
      .notification-list-wrapper {
        p.notf-title {
          border-bottom: 1px solid $gray-800;
          padding-bottom: 15px;
        }
      }

      ul {
        max-height: 290px;
        overflow: auto;
      }
    }

    .notification-body {
      border: none;
      padding: 0 10px;

      &::before {
        content: none;
      }
    }
  }
}

.action-wrapper {
  .content-body {
    padding: 15px;
  }

  &.custom-action-button {
    margin-left: -15px;
  }
}

.grid-progress {
  .mat-progress-bar {
    height: 10px;
    // .mat-progress-bar-buffer {
    //   background-color: #ffa49d;
    // }

    .mat-progress-bar-fill {
      &::after {
        background: $success-100;
        border-radius: 0;
      }
    }
  }
}
.cst-tooltip {
  .apexcharts-tooltip {
    white-space: inherit;
    .dot {
      margin-top: 8px;
      min-width: 6px;
    }
  }
}
.custom-tooltip {
  padding: 10px;
  background-color: $gray;
  width: 220px;

  .tooltip-title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-100;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid $gray-700;
  }

  .tooltip-detail {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 3px 0;
  }
}

.timelog-tooltip {
  .tooltip-detail {
    gap: 10px;

    .detail-title {
      white-space: nowrap;

      &:nth-child(2),
      &:last-child {
        width: 50px;
      }
    }
  }

  .dot {
    width: 6px;
    height: 6px;
    background-color: $success-100;
    border-radius: 100px;
    display: block;
  }
}

.overview-activity {
  ul {
    overflow: auto;
    height: 453px;

    li {
      padding: 15px 0;
      border-bottom: 1px dashed #dadada;

      &:last-child {
        border-bottom: none;
      }
    }

    .notification-body {
      border: none;
      padding: 0 10px;

      &::before {
        content: none;
      }
    }
  }
}

.inner-grid {
  .mat-header-cell {
    &:first-child {
      padding-left: 80px !important;
    }
  }
}

.overview-wrapper {
  .detail-card {
    .card {
      border: 1px solid $gray-700;
      border-radius: 6px;
      box-shadow: none;
      padding: 10px;
    }

    .fill-box {
      height: 14px;
      width: 14px;
      display: inline-block;
      background-color: $primary;
    }

    .unfill-box {
      height: 14px;
      width: 14px;
      background-color: $gray;
    }
  }

  .user-progress {
    .mat-progress-bar {
      height: 16px;

      .mat-progress-bar-fill::after {
        background: $primary;
        border-radius: 0;
      }
    }
  }

  .working-details {
    .working-list {
      padding: 12px 0;
      border-bottom: 1px dashed #dadada;

      .mat-icon {
        color: #909eb2;
      }

      &:last-child {
        border-bottom: none;
      }

      .fill-box {
        background-color: $success-100;
        text-align: center;
        color: $white;
        height: auto;
        width: 40px;
        padding: 3px;
        font-weight: normal;
      }

      .unfill-box {
        color: $gray-100;
        text-align: center;
        height: auto;
        width: 40px;
        padding: 2px;
      }
    }
  }

  .timetask-chart {
    .apexcharts-legend {
      display: block;
    }

    .apexcharts-graphical {
      transform: none;
    }

    .apexcharts-grid {
      line {
        display: none;
      }
    }
  }

  .tasks-details {
    .task-details-list {
      padding: 8px 0;

      .color-box {
        height: 16px;
        width: 16px;
        display: inline-block;
      }
    }

    .tasks-details-progress {
      div {
        height: 16px;
      }

      .clr-one {
        width: 18.75%;
      }

      .clr-two {
        width: 15.75%;
      }

      .clr-three {
        width: 6.12%;
      }

      .clr-four {
        width: 6%;
      }

      .clr-five {
        width: 9.5%;
      }

      .clr-six {
        width: 4.75%;
      }

      .clr-seven {
        width: 6.75%;
      }

      .clr-eight {
        width: 14.75%;
      }

      .clr-nine {
        width: 8.34%;
      }

      .clr-ten {
        width: 12.75%;
      }
    }

    .clr-one {
      background-color: #ae9ae8;
    }

    .clr-two {
      background-color: #7b76d3;
    }

    .clr-three {
      background-color: #f4c64d;
    }

    .clr-four {
      background-color: #f2816f;
    }

    .clr-five {
      background-color: #ba5397;
    }

    .clr-six {
      background-color: #c6df29;
    }

    .clr-seven {
      background-color: #7965f2;
    }

    .clr-eight {
      background-color: #ffde88;
    }

    .clr-nine {
      background-color: #b1ca6a;
    }

    .clr-ten {
      background-color: #7a5295;
    }
  }
}

.custom-calander {
  .weekdays {
    margin: 0;
    padding: 4px 0 10px 0;
    border-bottom: 1px solid $gray-700;

    li {
      display: inline-block;
      width: 13.6%;
      color: $gray-100;
      text-align: center;
    }
  }

  .days {
    padding: 10px 0;
    margin: 0;

    li {
      list-style-type: none;
      display: inline-block;
      width: 13.6%;
      text-align: center;
      margin-bottom: 5px;
      font-size: 12px;
      font-weight: 700;
      color: $gray-100;

      .green {
        color: #41da73;
        font-weight: normal;
      }

      .red {
        color: #ff3535;
        font-weight: normal;
      }

      .gray {
        color: #b4b4b4;
        font-weight: normal;
      }
    }
  }

  kendo-scheduler {
    .k-scheduler-toolbar {
      background-color: transparent;
      display: none;

      .k-button-solid-base {
        color: $gray-100;
        background-color: transparent;
        background-image: none;

        &:focus {
          box-shadow: none;
        }
      }

      .k-scheduler-views {
        &.k-button-group {
          display: none;
        }
      }
    }

    .k-scheduler-monthview {
      .k-scheduler-table {
        th {
          color: $gray-100;
          text-align: center;
          font-size: 13px;
          padding-inline: 0;
        }

        td {
          height: 52px;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
          color: $gray-100;
          padding-block: 5px;
          padding-inline: 5px;
        }
      }
    }

    .k-event {
      background-color: transparent;
      text-align: center;
      padding-right: 0;
      font-size: 12px;
      color: $gray-500;
      font-weight: 400;

      & > div {
        top: 8px;
      }

      .red {
        color: rgb(249, 112, 112);
      }

      .green {
        color: rgb(25, 214, 116);
      }
    }
  }
}

.job-list-table {
  .job-check {
    mat-checkbox {
      .mat-checkbox-inner-container {
        margin-right: 0;
      }
    }
  }
}

.list-check {
  &.mat-slide-toggle {
    .mat-slide-toggle-thumb-container,
    .mat-slide-toggle-thumb {
      width: 16px;
      height: 16px;
    }

    .mat-slide-toggle-thumb {
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .mat-slide-toggle-bar {
      background-color: transparent;
      border: 1px solid #646f85;
      width: 16px;
      height: 16px;
    }

    &.mat-checked {
      .mat-slide-toggle-label {
        .mat-slide-toggle-thumb-container {
          left: 0;
          transform: translate3d(0, 0, 0);
        }

        .mat-slide-toggle-bar {
          background-color: $success-100;
          border: 1px solid $success-100;
        }
      }

      span.mat-slide-toggle-thumb:after {
        color: $white;
      }
    }

    .mat-slide-toggle-thumb-container {
      top: 0;
      left: 0;
    }

    span.mat-slide-toggle-thumb:after {
      content: "\e876";
      position: absolute;
      top: -4px;
      left: 2px;
      font-size: 12px;
    }
  }
}

.mat-dialog-container {
  .timepicker-input {
    .full-width {
      .ngx-timepicker {
        width: auto;
      }
    }
  }
}

.mat-menu-panel {
  &.widget-menu {
    max-width: 550px;
  }
  .mat-menu-content {
    .sidenav-table {
      .mat-table-wrapper {
        border: none;
      }

      .mat-table {
        border-collapse: inherit !important;

        .mat-header-row {
          height: 48px;
        }

        .mat-row:hover {
          box-shadow: none;
        }

        .mat-header-cell {
          background-color: $white;
          border-top: none;
        }
      }
    }
  }

  &.setting-menu {
    width: 300px;

    .mat-menu-content {
      display: grid;
      grid-template-columns: auto auto;

      // overflow: auto;
      // height: 280px;
      .mat-menu-item:hover:not([disabled]):not(
          .mat-menu-panel .mat-menu-content .mat-menu-item.customize-view
        ) {
        background-color: transparent;
      }
    }
  }

  .context-section {
    padding: 15px;
    .context-value {
      min-width: 250px;
      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $gray-100;
      }
    }
  }
}

.quick-menu {
  width: 260px;
  .mat-menu-content {
    display: grid;
    grid-template-columns: auto auto;
    overflow: auto;
    // max-height: calc(100vh - 456px);
  }
}

.icon-top {
  margin-top: -5px;
}

.working-chart {
  .apexcharts-legend {
    justify-content: center !important;
  }
  .apexcharts-tooltip {
    border: 0 !important;
    background-color: $primary !important;

    &-series-group {
      background: $primary !important;
      color: $white !important;
    }
  }
}

// .common-list {
//   &.timesheet-hidelist {
//     .timelog-overview {
//       width: calc(100% - 0px);
//       margin-right: 30px;
//     }
//   }
// }

.timesheet-list {
  .action-wrapper {
    width: calc(100% - 130px);
  }

  &.timesheet-hidelist {
    .action-wrapper {
      width: calc(100% - 350px);
    }
  }
}

.custom-multiselect {
  .multiselect-dropdown {
    .dropdown-btn {
      padding: 10px 0 !important;
      background: transparent;
      box-shadow: 0 0 5px #0000001f;
      border: none !important;
      border-radius: 5px !important;
      align-items: center;
      height: 20px;

      .selected-item-container {
        .selected-item {
          border: 1px solid $primary-200 !important;
          background: $primary-200 !important;
          color: $primary !important;
          border-radius: 50px !important;
          line-height: normal;
          &:hover {
            box-shadow: none !important;
          }

          span {
            padding: 0 5px;
            width: 85px;
            display: inline-block;
            white-space: nowrap;
          }

          a {
            color: $primary !important;
            position: relative;
            top: -4px;
            padding: 0 5px;
          }
        }
      }

      span {
        padding-left: 5px;

        &:last-child {
          margin-right: 15px;
          padding-left: 4px;
        }
      }

      .dropdown-multiselect__caret {
        background-image: url(../images/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;

        &:before {
          border-width: 6px 6px 0 !important;
          content: none !important;
        }
      }
    }
  }
  &.job-dropdown {
    .multiselect-dropdown {
      .dropdown-list {
        width: 220px;
      }
      .dropdown-btn {
        .selected-item-container {
          .selected-item span {
            width: 40px;
          }
        }
        span:last-child {
          padding-left: 0;
          margin-right: 10px;
        }
      }
    }
  }
  &.inner-mutliselect {
    .multiselect-dropdown {
      .dropdown-list {
        width: 220px;
      }
    }
  }

  &.import-dropdown {
    .dropdown-list {
      bottom: 112%;
    }
  }

  &.w-295 {
    .multiselect-dropdown {
      width: 295px;
    }
  }

  .dropdown-list {
    padding: 0 !important;
    margin-top: 1px !important;
    box-shadow: 0 2px 4px -1px rgb($black, 0.2), 0 4px 5px rgb($black, 0.14),
      0 1px 10px rgb($black, 0.12) !important;

    ul {
      overflow-x: hidden !important;

      &.item2 {
        max-height: 131px !important;
      }
    }
  }

  .multiselect-item-checkbox {
    input[type="checkbox"] + div:after {
      left: 4px !important;
      width: 8px !important;
      height: 4px !important;
      margin-top: -4px !important;
      border-width: 0px 0 2px 2px !important;
    }

    input[type="checkbox"] + div:before {
      color: $gray-100 !important;
      border: 2px solid #00000040 !important;
      border-radius: 6px;
    }

    input[type="checkbox"]:checked + div:before {
      background: $primary !important;
      border: 2px solid $primary !important;
    }
  }
}

.dropdown-multiselect--active {
  .dropdown-multiselect__caret {
    transform: none !important;
  }
}

.timedata {
  mat-checkbox {
    .mat-checkbox-inner-container {
      margin-right: 2px;
    }
  }
}

.sidenav-container {
  .sidenav-drawer {
    .mat-drawer-inner-container {
      overflow: hidden !important;
    }
  }
}

.notification-wrapper {
  .info-tab {
    .mat-tab-body-wrapper {
      overflow: unset;

      .mat-tab-body {
        overflow: unset;

        .mat-tab-body-content {
          overflow: unset;
        }
      }
    }
  }
}

.k-calendar {
  .k-calendar-td.k-selected {
    .k-calendar-cell-inner {
      background-color: $primary;
    }

    .k-link {
      background-color: $primary;

      &:hover {
        background-color: $primary;
      }
    }
  }

  .k-calendar-view {
    .k-today {
      color: $primary;
    }
  }

  .k-calendar-navigation {
    li:hover {
      color: $primary;
    }

    li.k-hover {
      color: $primary;
    }
  }
}

.k-button-flat-primary {
  color: $primary;
}

.tooltip-list {
  ul {
    padding-left: 20px;
    overflow: auto;
    max-height: 165px;

    li {
      list-style: decimal;
      padding: 7px 0;
    }
  }
}

.client-list {
  .onelineclamp {
    width: 154px;

    .onelineclamp {
      max-width: 150px;
      width: auto;
    }
  }
}

.task-list {
  .onelineclamp {
    .onelineclamp {
      max-width: 240px;
      width: auto;
    }
  }
}

// Timer design
.timer-box {
  width: 300px;
  padding: 10px 10px 10px 30px;
  background-color: $primary-200;
  border-radius: 5px;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 999;
  display: block;
  border-left: 4px solid $primary;

  &.main-contain {
    .ng-select {
      .ng-select-container {
        background-color: $white;
      }
    }

    .timer-icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      line-height: 15px;
    }

    .drag-icon {
      position: absolute;
      left: 2px;
      color: $gray-300;
      cursor: move;

      .mat-icon {
        width: 26px;
        height: 26px;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }

  .start-timer {
    display: flex;
    align-items: center;

    mat-icon {
      color: $primary;
      padding-top: 5px;
      cursor: pointer;
      width: 26px;
      height: 26px;
      font-size: 26px;
      line-height: 26px;
      padding-right: 8px;
    }

    p {
      font-size: 18px;
      font-weight: 600;
      color: $primary;
      margin: 0;
      padding-left: 10px;
      border-left: 1px solid $gray-800;
    }
  }

  .timer-title {
    font-weight: 600;
    font-size: 16px;
    color: $primary;

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.comment-des {
  ul {
    padding-left: 0;
    padding-right: 5px;
    max-height: 220px;

    li {
      .timedate {
        min-width: 95px;

        span {
          min-width: 25px;
          display: inline-block;
          text-align: center;
        }
      }
    }
  }
}

.dropdown-module-list {
  left: auto !important;
  right: 0;
  max-width: 720px !important;

  ul {
    padding: 8px;
    display: grid;
    grid-template-columns: auto auto auto;

    li {
      list-style: none;
      border-radius: 10px;
      padding: 5px 8px 2px;
      margin: 5px;
      cursor: pointer;

      &:hover {
        background: #f7f1ff;
      }
      &.disable-box {
        .top-card {
          img {
            filter: grayscale(100%) opacity(0.5);
          }
          h6 {
            color: $gray-B100;
          }
          p {
            color: $gray-B100;
          }
        }
        a {
          cursor: not-allowed;
        }
      }

      .top-card {
        img {
          width: 25px;
          height: 30px;
        }

        h6 {
          color: $primary;
          font-size: 13px;
          font-weight: 500;
          margin: 0;
        }

        p {
          font-size: 12px;
          margin-bottom: 5px;
          font-weight: 400;
          color: $gray-B100;
        }
      }
    }
  }
}

.cust-bar-chart {
  height: 214px;

  .apexcharts-legend {
    display: block;
    max-height: 155px !important;

    .apexcharts-legend-series {
      margin-bottom: 12px !important;
    }

    .apexcharts-legend-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.permission-details {
  mat-checkbox {
    .mat-checkbox-inner-container {
      margin-right: 6px;
    }
  }
}

.expand-icon {
  position: absolute !important;
  right: 0;
  top: 0;
  mat-icon {
    width: 15px !important;
    height: 15px !important;
    font-size: 15px !important;
    line-height: 15px !important;
    margin-top: 3px;
  }
}

.alert-message {
  background-color: #fff0f0;
  color: $red;
  border-radius: 6px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  .mat-icon {
    color: $red;
  }
}

// Dashboard
.cust-progress {
  .mat-progress-bar {
    height: 10px;

    .mat-progress-bar-fill::after {
      background: $success-100;
      border-radius: 0;
    }
  }
}
.dashboard-wrapper {
  overflow: hidden;
}
.dashboard-wrapper,
.dashboard-card {
  .sidenav-body-content {
    height: calc(100vh - 90px);
    overflow: auto;
    padding: 15px 5px;
  }
  .mat-card {
    font-family: $fontFamily;
    padding: 0;
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }
  }
  .counts-details {
    margin: 10px 0;
    padding: 5px 30px;
    width: 25%;
    border-right: 1px solid $gray-800;
    &:last-child {
      border-right: 0;
    }
    .counts-details-view {
      display: flex;
      gap: 10px;
      padding: 10px 0;
    }
  }
  .custom-table {
    table {
      border-collapse: collapse;
      width: 100%;
      .count-status {
        padding: 10px 10px;
        border-radius: 5px;
        display: inline-block;
        white-space: nowrap;
        // span {
        //   height: 8px;
        //   width: 8px;
        //   margin-right: 8px;
        //   border-radius: 100%;
        //   display: inline-block;
        // }
        &.blue-box {
          background-color: #e9ecff;
          span {
            background-color: #c1c1c1;
          }
        }
        &.orange-box {
          background-color: #fef4df;
          span {
            background-color: #f1d071;
          }
        }
        &.green-box {
          background-color: #eafff4;
          span {
            background-color: #b9dd94;
          }
        }
      }
      tr {
        &:first-child {
          th {
            border-top: 0;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
        th,
        td {
          border: 1px solid $gray-800;
          text-align: center;
          padding: 8px;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
  .card {
    box-shadow: none;
    border: 1px solid $gray-800;
  }
  .dash-body {
    padding: 15px;
  }
  .dash-heading {
    background-color: $gray-900;
    padding: 9px 20px;
    border-radius: 14px 14px 0 0;
    .dash-title {
      font-size: 16px;
      color: $gray-100;
    }
  }
  .content-header-input {
    .mat-form-field {
      .mat-form-field-flex {
        box-shadow: none;
      }
      .mat-select-value {
        font-size: 20px;
        font-weight: 600;
        color: $primary;
        .mat-select-placeholder {
          color: $primary;
        }
      }
      &.mat-focused {
        .mat-form-field-flex {
          border: none;
        }
      }
    }
  }
  .card-wrapper {
    .card-body {
      justify-content: space-between;
      .card-text {
        span {
          color: $gray-100;
        }
      }
      .card-img {
        box-shadow: none;
        &.green-box {
          background-color: #eafff4;
          mat-icon {
            color: #4caf50;
          }
        }
        &.red-box {
          background-color: #ffeeea;
          mat-icon {
            color: #f44336;
          }
        }
        &.blue-box {
          background-color: #e9ecff;
          mat-icon {
            color: #55599a;
          }
        }
        &.orange-box {
          background-color: #fef4df;
          mat-icon {
            color: #e9a843;
          }
        }
      }
    }
  }
  .total-invoice {
    .total-invoice-wrapper {
      border-right: 1px solid $gray-800;
      padding-right: 30px;

      &:last-child {
        border: 0;
      }
    }
  }
  .dot {
    height: 6px;
    width: 6px;
    background-color: $success-100;
    border-radius: 100%;
    display: inline-block;
    margin-right: 5px;
  }
  .team-activity {
    .dash-list-view {
      .dash-list-body {
        height: 200px;
        overflow: auto;
      }
    }
  }
  .mat-table-wrapper {
    height: 222px;
    overflow: auto;
  }
  .mat-table {
    border-left: 1px solid $gray-800;
    tr.mat-row {
      height: 40px;
      &:hover {
        box-shadow: none;
      }
    }
    .mat-header-row {
      height: 40px;
      .mat-header-cell {
        background-color: $gray-900;
        border-top: none;
        border-bottom-color: $gray-800;
      }
    }
  }
  .dash-list-view {
    .dash-list-head {
      background-color: $gray-900;
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;
    }
    .dash-list-body {
      height: 200px;
      overflow: auto;
      .task-list-view {
        margin: 0 15px;
        padding: 10px 0;
        border-bottom: 1px solid $gray-800;
        img {
          border-radius: 50%;
        }
      }
    }
  }
}

.dashboard-card {
  &.cdk-drag-disabled {
    .e-close-icon {
      .mat-icon {
        cursor: not-allowed;
        user-select: none;
        color: $gray-B100;
      }
    }
  }
}

.top-switch-button {
  border-top: 1px solid $gray-700;
  padding: 15px 0;
  margin: 0 15px;
  button {
    &.action-button {
      border-radius: 6px;
      line-height: 30px;
      min-width: 90px;
      padding: 0 15px;
    }
  }
}

// syncfusion custom design
.e-control,
.e-css {
  font-family: $fontFamily;
  font-size: 14px;
}
.e-dashboardlayout.e-control {
  .e-panel {
    &.e-panel-transition {
      box-shadow: none;
      border: none;
      border-radius: 14px;
      z-index: auto !important;
    }
    .e-panel-container {
      display: none;
    }
  }
}

ejs-chart {
  .e-tooltip {
    text {
      tspan {
        font-weight: 400 !important;
      }
    }
  }
}

// support ticket
.ticket-wrapper {
  .mat-tab-group {
    .mat-tab-header {
      padding: 0;
    }
  }
}

.dragndrop-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .dashboard-card {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
}

.invite-check {
  display: grid;
  grid-template-columns: auto auto auto;
  .inactive {
    &.mat-checkbox {
      &.mat-checkbox-checked {
        cursor: not-allowed;
        .mat-checkbox-background {
          background-color: #ffe1df;
          .mat-checkbox-checkmark-path {
            stroke: #f44336 !important;
          }
        }
        .mat-checkbox-label {
          color: #f44336;
        }
      }
    }
  }
}

.payment-mlist {
  #payment-method_chart_legend_g,
  #invoice-category_chart_legend_g,
  #invoice-amount_chart_legend_g {
    g {
      clip-path: none !important;
    }
  }
}

.inner-invoice-info {
  .client-info-wrap {
    display: block !important;
  }
}

.task-dropdown {
  &.mat-autocomplete-panel {
    padding: 0 10px 10px;
    max-height: inherit !important;
    .mat-optgroup {
      .mat-optgroup-label {
        display: contents !important;
        line-height: 34px;
        height: 34px;
      }
      .mat-option {
        padding-left: 15px;
        height: 34px;
        &:not(.mat-option-multiple) {
          padding-left: 15px;
        }
      }
    }
  }
}

.subtask-list {
  img {
    position: relative;
    top: 3px;
    left: 3px;
  }
  .onelineclamp {
    max-width: 100px;
    min-width: 100%;
    padding-top: 2px;
  }
}

.log-title {
  display: flex;
  align-items: center;
  gap: 5px;
  .mat-icon {
    width: 16px;
    height: 16px;
    font-size: 15px;
    line-height: 20px;
    color: #41da73;
  }
}
